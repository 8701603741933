// Hiro colors
$primary:                 #1B84FF;
$primary-hover:           #0095e8;
$primary-dark:            #4759B5;
$primary-darker:          #464E5F;
$primary-light:           #E1F0FF;
$primary-light-text:      #B6DBFF;
$primary-text-muted:      #99DAFF;
$cyan:                    #96CFFB;
$primary-light-hover:     #b8d5f2;
$primary-rgba:            rgba($primary, 0.4);
$gray-bg:                 #f5f8fa;
$gray:                    #DADADA;
$gray-hover:              #dadce6;
$white:                   #fff;
$field-bg:                #FCFCFC;
$field-border:            #DBDFE9;
$active-field-bg:         #DCEFFF;
$gray-300:                #DADADA;
$gray-400:                #3f4254;
$gray-600:                #7e8299;
$disabled-text:           #ababab;
$dark:                    #181c32;
$red-light:               #fff5f8;
$red:                     #f1416c;
$green-light:             #F9FEF1;
$green:                   #50cd89;
$green-border:            #C8F0B6;
$yellow:                  #DFA000;
$yellow-border:           #FDEAB1;
$yellow-light:            #FFF8DD;
$purple:                  #ff0095;
$btn-text:                #4B5675;

// From new-layout (to be used in _main)
$new-dark: #3F4254;
$toolbar-icon: #8D8E99;

// Aliases
$dark-text: $dark;
$gray-text: $gray-400;
$gray-border: $gray;
$white-bg: $white;

// Translations (Metronic -> Hiro)
$gray-200: $field-bg;
$text-muted: $gray-text;