//
// Base
//

// Body
body {
  background: $page-bg;
}

#root {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.page-layout-custom {
  z-index: 1;
}

.text-danger {
  color: #D25D4A;
}

// Desktop Mode
@include media-breakpoint-up(lg) {

  // Wrapper
  .wrapper {

    // Fixed Header
    .header-fixed & {
      padding-top: get($header-config, desktop, fixed, height);
    }

    // Fixed Content Head
    .header-fixed.subheader-fixed.subheader-enabled & {
      padding-top: get($header-config, desktop, fixed, height) + get($subheader-config, fixed, height);
    }

    // Fixed Aside
    .aside-fixed & {
      padding-left: get($aside-config, base, width);
    }

    // Minimize Aside
    .aside-fixed.aside-minimize:not(.aside-minimize-hover) & {
      padding-left: get($aside-config, base, minimized-width);
    }
  }

  // Container
  .container,
  .container-fluid {
    padding: 0 get($page-padding, desktop);
  }
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {

  // Wrapper
  .wrapper {

    // Fixed Header
    .header-mobile-fixed & {
      padding-top: get($header-config, tablet-and-mobile, fixed, height);
    }

    // Fixed Subheader
    .header-mobile-fixed.subheader-mobile-fixed.subheader-enabled & {
      padding-top: get($header-config, tablet-and-mobile, fixed, height) + get($subheader-config, fixed, height-mobile);
    }
  }

  // Container
  .container,
  .container-fluid {
    max-width: none;
    padding: 0 get($page-padding, tablet-and-mobile);
  }
}


// custom style ALL

body {
  background: $gray-bg;
}

.checkbox {
  font-weight: normal !important;
  font-size: inherit;
  gap: 8px;

  &.checkbox-white {
    &>span {
      background-color: #fff;
    }
  }

  &.checkbox-outline {
    &>.checkbox-check {
      margin-right: 10px;
    }

    &>.checkbox-check,
    &>input:checked~.checkbox-check {
      border-color: $gray;
    }
  }

  .checkbox-label {
    display: inline-block;
    font-style: normal;
  }

  & > span {
    border-color: $field-border !important;
  }
}

.aside {
  .aside-menu {
    background: $white;
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    .menu-nav>.menu-item>.menu-link .menu-text {
      color: $gray-text;
    }

    .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link,
    .menu-nav>.menu-item.menu-item-active>.menu-link,
    .menu-nav>.menu-item.menu-item-open>.menu-link {
      background: $gray-bg;
      position: relative;

      &::before {
        width: 4px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background: $primary;
        content: "";
      }

      .menu-text {
        color: $dark-text;
      }
    }
  }
}

.container-small {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
}

//.no-product-item,
//.product-items{
//  display: none;
//}

.no-product {
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  text-align: center;

  .row {
    width: 100%;
  }

  .no-product-item {
    display: block;
  }
}

.no-product-item {
  .empty-icon {
    width: 240px;
    height: auto;
  }
}

.product {
  margin-top: -50px;

  .product-items {
    display: block;
  }
}

.product-setting .MuiTypography-root {
  margin-top: 40px;
}

.title-double {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-table {

  .table.table-head-bg thead tr,
  .table.table-head-bg thead th {
    /*background: transparent;
    color: $black !important;
    text-transform: inherit;
    font-weight: normal;
    font-size: 15px;*/
  }

  .table {
    border-spacing: 0 22px;
    border-collapse: initial;

    th {
      padding: 13px 24px 0;
    }

    td {
      padding: 16px 24px;
      background: $white;

      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }

  .pending {

    td:nth-child(1),
    td:nth-child(2),
    td:nth-child(4) {
      &>* {
        opacity: .5;
      }
    }
  }
}

.label.label-draft {
  background: $orange;
}

.label.label-live {
  background: $green;
}

// custom style new page

.header-page {
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  border-bottom: 1px solid $gray;

  &-title {
    .btn-close {
      margin-right: 20px;
      line-height: 1;
      display: inline-flex;
      align-items: center;
    }

    h1 {
      margin-bottom: 0;
    }
  }
}

.custom-page {
  position: relative;

  .alert {
    color: $white;
    border: none;
  }

  .header-page {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    background: $white;
    z-index: 99999;
  }
}

// STEPS
.title-sub {
  font-size: 16px;
  color: $gray-text;
  font-weight: 500;
}

.custom-page,
.product-setting,
.episodeEditPage {

  .MuiTypography-root,
  .jss1 {
    background: transparent;
  }

  .steps-block {
    .step-block {
      height: 100%;
    }

    .makeStyles-root-1,
    >div {
      height: 100%;
      display: flex;
      justify-content: space-between;
    }

    p.MuiTypography-root {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  textarea.form-control {
    height: auto;
  }

  .makeStyles-instructions-4 {
    margin: 0;
  }

  .create-product-page {
    display: flex;
    width: 100%;
  }

  .podcast-created-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: $dark;
      opacity: 0.48;
      z-index: 1;
    }

    &-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 742px;
      height: 435px;
      background: $white-bg;
      border-radius: 8px;
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-icon {
        margin-top: 48px;
        width: 68px;
        height: 68px;
        margin-bottom: 24px;
      }

      &-title {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: $dark;
        margin-bottom: 8px;
      }

      &-sub-title {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 19px;
        text-align: center;
        color: $gray-text;
        margin-bottom: 32px;
      }

      &-first-link {
        background: $primary;
        border-radius: 6px;
        border: none;
        box-shadow: none;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $white;
        text-align: center;
        padding: 12px 56px;
        margin-bottom: 8px;
        transition: color 0.15s ease,
          background-color 0.15s ease;

        &:hover {
          background-color: $primary-hover;
          color: $white;
          cursor: pointer;
        }
      }

      &-second-link {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: $gray-text;
        padding: 12px 30px;
        border: 2px solid $gray;
        background: transparent;
        box-shadow: none;
        border-radius: 6px;
        margin-bottom: 20px;
        transition: color 0.15s ease,
          background-color 0.15s ease,
          border-color 0.15s ease;

        &:hover {
          background-color: $primary-hover;
          border: 2px solid transparent;
          color: $white;
          cursor: pointer;
        }
      }

      &-third-link {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $gray-text;
        transition: color 0.15s ease;
        background: transparent;
        border: none;
        box-shadow: none;

        &:hover {
          color: $primary-hover;
          cursor: pointer;
          text-decoration: underline !important;
        }
      }
    }
  }

  .create-podcast-content,
  .product-setting-content,
  .episode-edit {
    &.review-page-content-section {}

    .items {
      transition: transform .2s;

      &.shift {
        &-0 {
          transform: translateY(0);
        }

        &-1 {
          transform: translateY(-367px);
        }

        &-2 {
          transform: translateY(-734px);
        }

        &-3 {
          transform: translateY(-1101px);
        }

        &-4 {
          transform: translateY(-1468px);
        }

        &-5 {
          transform: translateY(-1835px);
        }
      }
    }

    .card {
      max-width: 755px;
      margin-left: auto;
      margin-right: auto;

      &:last-child {}

      .card-body {
        padding-top: 39px;
        padding-bottom: 39px;
        border-radius: 8px;

        .card-element-content-dropzone {
          padding-bottom: 19px;

          .invalid-feedback-copy {
            margin-top: 25px;
          }
        }

        h2 {
          margin-bottom: 35px;

          span {
            color: $gray-text;
            margin-right: 11px;
          }

          button {
            margin-left: 10px;
          }
        }

        .title-row {
          margin-bottom: 35px;
        }

        .content-description {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: $dark-text;
          max-width: 596px;
          margin-bottom: 23px;
        }

        .content-dropzone {
          background-color: $white-bg;
          margin-bottom: 20px;
          width: 100%;
          max-width: 662px;

          .dropzone {
            width: 100%;
          }

          .dropzone-upload {
            display: none;
            background: $white-bg;
            padding-left: 0;
            padding-bottom: 0;
            padding-top: 30px;
            padding-bottom: 27px;
          }

          &.uploaded {
            margin-bottom: 0;

            .dropzone {
              display: none;
            }

            .dropzone-upload {
              display: flex;
            }
          }

          .dropzone-message-normal {
            font-weight: normal;
            font-size: 13px;
          }

          .image-input-wrapper {
            width: 150px !important;
            height: 150px !important;
          }

          .dropzone-msg-icon {
            margin-bottom: 18px;
          }

          &-description {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: $dark-text;

            span {
              color: $primary;
            }
          }

          &.dropzone.dropzone-default .dropzone-msg>div[tabindex] {
            background: rgba(243, 246, 249, 0.5);
            border: 1px dashed $gray;
            border-radius: 8px;
            padding-top: 39px;
            padding-bottom: 9px;
          }

          &>div {
            &:first-child {
              width: 100%;
              padding: 0;
            }
          }
        }

        .episode-access {
          &-title {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: $dark-text;
            margin-bottom: 20px;
            display: inline-block;

            .step {
              text-transform: uppercase;
              color: $gray-text;
            }
          }
        }

        &.episode-access {
          .form-label {
            display: block;
          }

          .upgrade-link {
            float: right;
          }
        }

        .episode-access-options {
          gap: 20px;

          .option {
            flex: 1 1 155px;
            border: 1px dashed $field-border;
            margin-bottom: 0px;
            border-radius: 10px;
            cursor: pointer;
            flex-direction: column;
            text-align: center;
            font-weight: normal;
            font-size: .9em;
            align-items: center;
            justify-content: center;
            line-height: 1.3em;
            min-height: 100px;
            transition: border-color .2s, background-color .2s, color .2s;

            input {
              display: none;
            }

            &.with-radio {
              text-align: left;
              flex-direction: row;
              gap: 20px;
              line-height: inherit;

              .option-label {
                strong {
                  padding-bottom: 4px;
                  display: inline-block;
                }
              }
              
              &::before {
                display: block;
                content: "";
                flex: 0 0 auto;
                width: 17px;
                height: 17px;
                border-radius: 100%;
                border: 1px solid $field-border;
                background-color: white;
                transition: border-color .2s, background-color .2s;
              }

              &.active {
                &::before {
                  border: 4px solid $primary;
                }
              }
            }

            svg {
              margin-bottom: 10px;
              transition: filter .2s;
              width: 40px;
              height: 40px;
              filter: grayscale(1);
            }

            &.active {
              color: $primary;
              border-color: $primary;
              background-color: $primary-light;

              svg {
                filter: none;
              }
            }

            &.disabled {
              opacity: .5;
            }
          }
        }

        &.ownership {
          .content-input {
            &.podcast-author {
              margin-bottom: 4px;
            }
          }

          .owner-copyright {}

          .content-description {}

          .owner-name-and-email {
            display: flex;

            .visible-error-message {
              display: block;
            }

            .content-input {
              margin-left: 0;

              &.is-email-invalid {
                input {
                  border-color: $red;
                }
              }

              &:first-child {}
            }
          }
        }

        .content-input {
          margin-bottom: 29px;

          &:last-child {}

          .form-label {
            color: $dark-text;
            font-size: 14px;
            line-height: 21px;
          }
        }

        &.podcast-player {
          h2 {
            margin-bottom: 18px;
          }
        }

        &.listener-access-email {
          h2 {
            margin-bottom: 18px;
          }

          .content-description {
          }

          .editor-section {
            width: 564px;
            height: 238px;
            overflow: hidden;
            margin-bottom: 44px;
            border: 1px solid $gray;
            border-radius: 12px;
            padding-left: 43px;
            padding-right: 35px;
            padding-top: 29px;

            &>div {
              height: 238px !important;
              border: none;
            }
          }

          .form-navigation {
            border-top: 1px solid $gray;
            padding-top: 26px;
            display: flex;
            justify-content: flex-end;

            .button-next {
              border: none;
              box-shadow: none;
              background: $primary;
              padding: 12px 32px;
              border-radius: 6px;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: $white;
            }
          }
        }

        &.review-section {
          padding-top: 40px;

          .reviw-podcast-overview {
            display: flex;
            margin-bottom: 22px;

            &-poster {
              width: 67px;
              height: 67px;
              background: $gray-bg;
              border-radius: 6px;
              margin-right: 16px;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
              }
            }

            &-content {
              &-title {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                color: $dark-text;
                margin-bottom: 2px;
              }

              &-description {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                color: $gray-text;
                margin-bottom: 8px;
              }

              &-author {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                color: $gray-text;
                margin-bottom: 0;

                .podcast-author-title {
                  margin-right: 4px;
                }

                .podcast-author-value {
                  color: $primary;
                }
              }
            }
          }

          &-title {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: $dark-text;
            margin-bottom: 32px;
          }

          .sub-title {
            position: relative;
            background: $gray-bg;
            border-radius: 8px;
            padding: 8px 16px;
            margin-bottom: 24px;

            span {
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              color: $dark-text;
            }

            .edit-button {
              position: absolute;
              top: 4px;
              right: 8px;
              width: 24px;
              height: 24px;
              border: none;
              box-shadow: none;
              padding: 0;
              margin: 0;
              background: transparent;
            }
          }

          .review-fields {
            display: flex;
            flex-direction: column;

            &.first-list {
              margin-bottom: 24px;
            }

            &-item {
              display: flex;
              font-style: normal;
              font-weight: normal;
              font-size: 13px;
              line-height: 19px;
              margin-bottom: 12px;

              &.review-email-item {
                height: 147px;
              }

              &:last-child {
                margin-bottom: 0;
              }

              &-key {
                width: 157px;
                display: flex;
                justify-content: flex-end;
                color: $gray-text;
                margin-right: 8px;

                &.email-block {
                  margin-bottom: 27px;
                }
              }

              &-value {
                width: 463px;
                color: $dark-text;

                span {
                  display: inline-block;
                }
              }
            }
          }

          .form-navigation {
            position: relative;
            border-top: 1px solid $gray;
            padding-top: 26px;
            display: flex;
            justify-content: flex-end;

            .button-back {
              border: none;
              box-shadow: none;
              background: transparent;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: $gray-text;
              margin-right: 40px;
            }

            .button-save-draft {
              position: absolute;
              left: 0;
              border: solid 1px $gray-text;
              box-shadow: none;
              background: transparent;
              padding: 12px 32px;
              border-radius: 6px;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: $gray-text;
              transition: color .15s ease,
                background-color .15s ease,
                border-color .15s ease,
                box-shadow .15s ease;

              &:hover {
                background-color: $primary-hover;
                border: solid 1px $primary-hover;
                color: $white;
              }

              &:disabled {
                color: $gray;
                background: $gray-bg;
                border-color: $gray-bg;
                opacity: .6;
                box-shadow: none;
                cursor: initial;
              }
            }

            .button-next {
              border: none;
              box-shadow: none;
              background: $primary;
              padding: 12px 32px;
              border-radius: 6px;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: $white;
              transition: color .15s ease,
                background-color .15s ease,
                border-color .15s ease,
                box-shadow .15s ease;

              &:hover {
                background-color: $primary-hover;
              }

              &:disabled {
                color: $gray;
                background: $gray-bg;
                border-color: $gray-bg;
                opacity: .6;
                box-shadow: none;
                cursor: initial;
              }
            }
          }
        }
      }
    }

  }

  .product-setting-content {
    width: auto;
    height: initial;
    align-items: flex-start;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;

    .card {
      position: relative;

      .listener-access-email {

        .content-description,
        .editor-section {}

        .editor-section {
          margin-bottom: 0;
        }
      }

      .episode-access,
      .listener-access-email {
        h2 {
          span {
            display: none;
          }
        }
      }
    }

    .delete-section-element {
      .card-body {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &-title {
        margin: 0 !important;
      }

      &-button {
        width: 160px;
        padding-top: 12px;
        padding-bottom: 12px;
        background: $red;
        border-radius: 4px;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 19px;
        text-align: center;
        color: $white;
        border: none;
        box-shadow: none;
      }
    }
  }

  .create-podcast-sidebar {
    width: 496px;
    height: 100vh;
    background: transparent;
    display: flex;
    flex-direction: column;
    background: $white;
    padding-left: 97px;
    padding-top: 50px;
    overflow-y: hidden;

    .btn-close {
      width: 20px;
    }

    h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: $dark-text;
      width: 240px;
      margin-top: 27px;
      margin-bottom: 53px;
    }

    &-item {
      display: flex;
      position: relative;
      align-items: center;
      width: 286px;
      height: 59px;
      padding-left: 8px;
      margin-bottom: 16px;
      justify-content: flex-start;

      &-active {
        background-color: $gray-bg;
        border-radius: 4px;

        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 20px;
          height: 20px;
          background-color: $gray-bg;
          border-radius: 2px;
          top: 50%;
          right: 0;
          transform: translateX(50%) translateY(-50%) rotate(45deg);
        }
      }

      &:hover {
        background-color: $gray-bg;
        border-radius: 4px;
        cursor: pointer;

        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 20px;
          height: 20px;
          background-color: $gray-bg;
          border-radius: 2px;
          top: 50%;
          right: 0;
          transform: translateX(50%) translateY(-50%) rotate(45deg);
        }

        .create-podcast-sidebar-item-icon {
          background-color: $gray-bg;
          color: $blue;
        }
      }

      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        color: $gray-text;
        border-radius: 8px;
        width: 41px;
        height: 41px;
        line-height: 50px;
        text-align: center;
        background-color: $gray-bg;
        position: relative;
        transition: color .2s, background-color .2s;
        margin-right: 14px;

        img {
          opacity: 0;
          transition: opacity .2s;
          position: absolute;
        }

        &.active {
          background-color: #E1F0FF;
          color: $blue;
        }

        &.error {
          background-color: $red-light;
          color: $red;
        }

        &.completed {
          background-color: #E1F0FF !important;
          font-size: 0;

          img {
            opacity: 1;
          }
        }
      }

      &-content {
        text-align: left;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-top: 7px;

        &-title {
          font-size: 14px;
          font-weight: 500;
          color: $dark-text;
          margin-bottom: 3px;
        }

        &-description {
          font-size: 13px;
          color: $gray-text;
        }
      }
    }

    .MuiStepConnector-root.MuiStepConnector-horizontal {
      display: none;
    }

    .MuiStepLabel-iconContainer {
      padding-right: 16px;
    }

    .MuiStepLabel-label {
      text-align: left;

      &Container {
        width: 100%;
        height: 100%;
        padding-top: 7px;
      }

      .MuiStep-title {
        font-size: 14px;
        font-weight: 500;
        color: $dark-text;
        margin-bottom: 5px;
      }

      .MuiStep-description {
        font-size: 13px;
        color: $gray-text;
      }

      &.MuiStepLabel-completed {
        color: $gray-text;
      }
    }

    .MuiStepIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      color: $gray-text;
      border-radius: 8px;
      width: 41px;
      height: 41px;
      line-height: 50px;
      text-align: center;
      background-color: $gray-bg;
      position: relative;
      transition: color .2s, background-color .2s;

      img {
        opacity: 0;
        transition: opacity .2s;
        position: absolute;
      }
    }

    .MuiStepIcon-active {
      background-color: $gray-bg;
      color: $blue;
    }

    .MuiStepIcon-error {
      background-color: $red-light;
      color: $red;
    }

    .MuiStepIcon-complete {
      background-color: $primary !important;
      font-size: 0;

      img {
        opacity: 1;
      }
    }

    .MuiSvgIcon-root {
      width: 50px;
      height: 50px;
    }

    .step-block {
      height: 100%;
    }

    ~div {
      .card {}
    }

    // Media
    @media screen and (min-width: 1024px) and (max-width: 1300px) {
      & {
        width: 370px;
        padding-left: 40px;
      }
    }

    @media screen and (max-width: 1023px) {
      & {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 36px;
        padding-top: 0;
        padding-left: 0;
        flex-direction: row;
        align-items: center;
        z-index: 3;

        .btn-close {
          width: 40px;
          height: 36px;
          display: flex;
          padding: 10px;
        }

        h1 {
          display: none;
        }

        .create-podcast-sidebar-item {
          display: none;
        }
      }
    }
  }

  .MuiStepButton-root {
    margin: 0;
    padding: 0;
    margin-bottom: 16px;
  }

  .MuiStepper-horizontal {
    align-items: flex-start;
  }

  .MuiStepIcon-root.MuiStepIcon-active,
  .MuiIcon-colorSecondary {
    color: $primary;

    .MuiStepIcon-text {
      fill: $blue;
    }
  }

  .MuiStepIcon-root {
    color: $gray-bg;
  }

  .MuiButton-root {
    padding: 0;
    min-width: 32px;
    vertical-align: middle;
    overflow: visible;

    .MuiTouchRipple-root {
      display: none !important;
    }

    &:hover {
      background: transparent;
    }
  }
}

.MuiTooltip-tooltip {
  font-size: 13px !important;
  font-weight: normal;
}

.checkbox-custom {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 26px;

  &-item {
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(33.3333% - 20px);

    img.active {
      display: none;
    }

    input {
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      position: absolute;
      opacity: 0;
      cursor: pointer;

      &:checked+.checkbox-custom-block {
        border-color: $primary;
        color: $primary;
        background: $primary-rgba;

        img {
          path {
            fill: $primary !important;
          }
        }

        .checkbox-custom-icon img {
          display: none;
        }

        img.active {
          display: block;
        }
      }
    }
  }

  &-block {
    display: flex;
    padding: 22px 25px 24px;
    border: 2px solid $gray;
    background: $gray-bg;
    border-radius: 12px;
    align-items: center;
    margin-bottom: 0;
    font-size: 16px;

    .pro {
      &-icon {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &-icon {
    margin-right: 13px;
  }
}

.link-pro {
  color: $warning;
  display: inline-flex;
  align-items: center;
  font-size: 1.1em;
  text-decoration: underline;

  &-icon {
    margin-right: 10px;
    margin-bottom: 4px;
  }
}

.link-pro:hover {
  color: $warning;
  text-decoration: none;
}

.small-input {
  .form-control {
    max-width: 350px;
  }
}

.normal-input {
  .form-control {
    max-width: 460px;
  }
}

.big-input {
  .form-control {
    max-width: 600px;
  }
}

.custom-select-wrapp {
  display: flex;
  align-items: center;

  p {
    margin-bottom: 0;
  }

  .form-control {
    max-width: 129px;
    margin-right: 20px;
  }
}

// price

.price {
  &-item {
    border: 2px solid $gray;
    border-radius: 20px;

    h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: $black;
    }
  }

  &-list {
    list-style: none;
    padding: 0;
    margin: 31px 0 50px;
    color: $black;
    font-size: 16px;
    line-height: 24px;

    li {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &-title {
    color: $black;
    margin-bottom: 20px;

    h3 {
      font-size: 80px;
      line-height: 120px;
      font-weight: normal;
      display: flex;
      align-items: flex-start;
    }

    span {
      font-size: 36px;
      line-height: 54px;
      margin-top: 15px;
    }
  }

  &-pack {
    text-align: left;

    h2 {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
    }

    &__list {
      list-style: none;
      padding: 0;
      margin: 0;
      margin-bottom: 30px;

      li {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $dark-text;

        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }

      svg {
        margin-right: 12px;
      }
    }

    &__bottom {
      font-size: 16px;
      line-height: 24px;
      max-width: 310px;
      width: 100%;
      border-top: 1px solid $gray;
      color: $black;
      text-align: center;
      padding-top: 20px;
      margin-top: 30px;
    }
  }

  &-card__list {
    list-style: none;
    display: flex;
    width: 100%;
    padding-left: 30px;
    margin-bottom: 30px;

    li {
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
}

.quote {
  padding-top: 38px;
  position: relative;
  display: inline-flex;
  align-items: center;

  &-icon {
    width: 44px;
    height: 44px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 14px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &-content {
    font-size: 16px;
    line-height: 24px;
    color: $dark-text;
  }

  &::before {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;
    font-family: "Times New Roman", Times, serif;
    line-height: 74px;
    font-size: 64px;
    font-weight: bold;
    color: $green;
    content: "“";
  }
}

.price-page {

  .MuiTab-root {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .card-icon {
    color: $black;
    margin-right: 15px !important;
  }

  .price-header-nav {
    display: flex;
    align-items: center;

    .btn {
      margin-right: 30px;
    }
  }

  .card-header {
    display: flex;
    align-items: center;
  }

  .MuiAppBar-colorPrimary {
    background: none;
    box-shadow: none;
  }

  .MuiTabs-flexContainer {
    display: inline-flex;
    margin: 0 auto;
    justify-content: center;
    border-radius: 30px;
    background: $primary-light;
  }

  .MuiTab-root {
    border-radius: 30px;
    min-height: 40px;
    font-size: 14px;
    font-weight: 600;
  }

  .PrivateTabIndicator-root-2 {
    display: none;
  }

  .font-size-h1 {
    font-size: 48px !important;
  }

  .font-size-h2 {
    font-size: 32px !important;
  }

  .Mui-selected {
    background: $primary;
    color: $white;
  }
}

.bg-price {
  background: $dark;
}

.bg-primary {
  background: $primary;
}

.price-list {

  dd,
  dt {
    display: block;
    width: 70%;
    text-align: left;
  }

  dt {
    width: 30%;
  }
}

.text-green {
  color: $green;
}

.navigation-menu-custom {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    &:last-child {
      span {
        margin-top: -4px;
      }
    }

    &:not(:last-child) {
      margin-right: 25px;
    }
  }

  &-link {
    display: inline-flex;
    align-items: center;
    padding: 0 24px 0 17px;
    color: $dark;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;

    span {
      margin-right: 15px;
    }

    &.current,
    &:hover {
      background: $gray-bg;
      color: $primary;

      svg {

        path,
        rect {
          fill: $primary;
        }
      }
    }
  }
}

.sub-header-link {
  .dropdown-item {
    display: inline-flex;
    align-items: center;

    img,
    svg {
      margin-right: 16px;
      width: 15px;
      height: 15px;
    }
  }
}

// product setting

.product-setting {
  position: relative;

  .card.card-custom {
    box-shadow: none;
    max-width: 1100px;
    padding: 24px 60px 24px 24px;

    &.delete {
      padding-right: 24px;
    }
  }

  .MuiAppBar-colorPrimary {
    background: $white;
    justify-content: space-between;
  }

  .MuiAppBar-root {
    flex-direction: row;
    align-items: center;
    margin-left: -25px;
    margin-right: -25px;
    padding-left: 50px;
    padding-right: 25px;
    width: auto !important;
    box-shadow: none;
    position: fixed;
    top: 135px;
    left: 290px;
    right: 25px;
    z-index: 999;
    border-top: 1px solid $gray;
  }

  .MuiTabs-root {
    min-height: 50px;
  }

  .MuiTabs-flexContainer {
    button {
      &:not(last-child) {
        margin-right: 20px;
        margin-top: 10px;
        margin-bottom: 5px;
      }
    }
  }

  .makeStyles-root-1 {
    background: transparent;
  }
}

// episode page

.episodes {
  .episodes-item {
    display: none;
  }

  .no-episodes-item {
    padding-top: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &.episodes-table {
    padding-top: 10px;

    .episodes-item {
      display: block;
    }
  }
}

.episodes-item {
  .card-toolbar {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &-search-btn {
      display: flex;
    }
  }

  .MuiPaper-root {
    box-shadow: none !important;
    background-color: $gray-bg;
    margin-right: 20px;
  }
}

.draggedEpisode {
  background: #fff;
  border-radius: 10px;
  z-index: 99 !important;

  td {
    display: none;
    padding: 0.75rem;
    vertical-align: middle;
  }

  td:nth-child(1) {
    display: table-cell;
    width: 40px;
  }

  td:nth-child(2) {
    display: table-cell;
    width: 100%;
  }
}

// music custom style

.music-vision {
  position: relative;
  display: flex;
  max-width: 202px;
  height: 48px;
  width: 100%;
  border-radius: 6px;
  padding-left: 41px;
  background: $gray-bg;

  &-bg {
    img {
      max-width: 100%;
    }
  }

  &-play {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    background: $primary;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    cursor: pointer;

    i {
      color: $white;
      font-size: 12px;
      margin-left: 1px;
    }
  }

  &-duration {
    position: absolute;
    bottom: 6px;
    right: 6px;
    padding: 2px 8px;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    border-radius: 4px;
    background: $white;
    z-index: 5;
  }

  &-range {
    input[type='range'] {
      overflow: hidden;
      width: 100%;
      height: 100%;
      -webkit-appearance: none;
      background-color: transparent;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    input[type='range']::-webkit-slider-runnable-track {
      height: 10px;
      -webkit-appearance: none;
      color: transparent;
      background: transparent;
      margin-top: -1px;
    }

    input[type=range]::-moz-range-track {
      height: 48px;
      -webkit-appearance: none;
      color: transparent;
      background: transparent;
      margin-top: -1px;
      border: 0 solid ($primary, .1);
    }

    input[type=range]::-ms-track {
      height: 48px;
      -webkit-appearance: none;
      color: transparent;
      background: transparent;
      margin-top: -1px;
    }

    input[type='range']::-webkit-slider-thumb {
      width: 0;
      -webkit-appearance: none;
      height: 10px;
      cursor: ew-resize;
      background: ($primary, .1);
      box-shadow: -200px 0 0 200px rgba($primary, .1);
    }

    input[type=range]::-moz-range-thumb {
      width: 0;
      -webkit-appearance: none;
      height: 48px;
      cursor: ew-resize;
      background: ($primary, .1);
      box-shadow: -200px 0 0 200px rgba($primary, .1);
      border: 0 solid ($primary, .1);
    }

    input[type=range]::-ms-thumb {
      width: 0;
      -webkit-appearance: none;
      height: 48px;
      cursor: ew-resize;
      background: ($primary, .1);
      box-shadow: -200px 0 0 200px rgba($primary, .1);
      border: 0 solid ($primary, .1);
    }

    /** FF*/
    input[type="range"]::-moz-range-progress {
      background-color: rgba($primary, .1);
      height: 48px;
      border: 0 solid ($primary, .1);
    }

    input[type="range"]::-moz-range-track {
      background-color: rgba($primary, .1);
      border: 0 solid ($primary, .1);
    }

    /* IE*/
    input[type="range"]::-ms-fill-lower {
      background-color: rgba($primary, .1);
    }

    input[type="range"]::-ms-fill-upper {
      background-color: transparent;
    }
  }
}

// create new podcast

.create-new-podcast {
  border-top: 1px solid $gray;

  &.border-none {
    border: none;
  }

  &-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 600px;
    margin: 0 auto;

    &-logo {
      display: flex;
      align-items: center;

      img {
        width: 120px;
        height: 120px;
        border-radius: 13px;
        overflow: hidden;
        margin-right: 50px;
      }
    }

    &-content {
      width: 260px;
      font-size: 14px;

      h4 {
        font-size: 17px;
      }
    }
  }

  &-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 600px;
    margin: 60px auto 0;

    +.create-new-podcast-bottom {
      margin: 60px auto 0;
    }
  }

  &-list {
    list-style: none;
    padding: 0 0 0 98px;
    margin: 0;

    .create-text {
      display: flex;
      align-items: flex-start;

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      span {
        &:first-child {
          color: $dark-text;
          margin-right: 5px;
        }
      }
    }

    li {
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: flex-start;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      >div {
        width: calc(100% - 74px);
        padding-right: 20px;
      }
    }

    img,
    .create-new-podcast-list-icon {
      width: 24px;
      margin-right: 50px;
    }
  }

  &-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 600px;
    margin: 15px auto 0;

    &:first-child {
      margin-top: 60px;
    }

    &:not(:last-child) {
      padding-bottom: 0px;
    }

    &-content {
      padding-left: 98px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;

      img {
        width: 24px;
        margin-right: 50px;
      }
    }
  }
}

.modal .modal-header .close span {
  display: inline-block;
}

.dropzone {
  width: 100%;
  position: relative;
  background-color: $primary-light;
  border: 1px dashed $primary;
  border-radius: 8px !important;
  overflow: hidden;
  color: $gray-text;
  font-size: 12px;
  padding: 0;
  transition: box-shadow .2s, background-color .2s, border-color .2s;
  text-align: center;

  .dropzone-content {
    display: flex;
    gap: 30px;
    align-items: center;
    text-align: left;
    line-height: 23px;
  }

  >div {
    padding: 30px;
  }

  * {
    cursor: pointer;
    transition: color .2s;
  }

  img {
    display: block;
    margin: 0 auto 10px;
  }

  .dropzone-icon-error {
    display: none;
  }

  h3 {
    font-size: 14px;
    font-weight: 500;
    color: $dark;
    margin: 0;

    >span>span {
      color: $primary;
    }

    .dropzone-message-error {
      color: $red-light;
      display: none;
    }
  }

  p {
    margin: 0;
  }

  &.dropzone-has-error {
    background-color: $red-light;
    border-color: $red;

    .dropzone-icon,
    .dropzone-message-normal {
      display: none;
    }

    .dropzone-icon-error,
    .dropzone-message-error {
      display: block;
      color: $red;
    }

    h3,
    p {
      color: $red;
    }
  }

  &.dropzone-drag-active {
    box-shadow: 0 0 0 8px $gray;
  }

  &.dropzone-has-progress {
    > div {
      padding: 21px;
    }

    .dropzone-content {
      display: none;
    }

    .dropzone-progress-container {
      h3 {
        margin-bottom: 5px;
      }

      .progress-text {
        font-size: 10px;
        margin-bottom: 7px;

        .btn {
          height: auto !important;
        }
      }

      .progress {
        background-color: $white;
        height: 14px;
        border-radius: 14px;
        max-width: 82%;
        margin: auto;
      }
    }
  }
}

.dropzone-sm {
  .dropzone {
    &>div {
    }

    h3 {
    }

    .dropzone-description {
      display: none;
    }

    &.dropzone-has-progress {
      .dropzone-progress-container {
      }
    }
  }
}

.dropzone-bulk-container {
  width: 100%;

  .dropzone {
    margin: 0 auto 2rem;
  }

  .dropzone-upload-file-item {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;

    h3 {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    .progress-text {
      font-size: 10px;
      color: $gray-text;
      margin-bottom: 8px;
      height: 18px;

      .btn {
        height: auto !important;
      }
    }

    .progress {
      font-size: 0;
      max-width: 280px;
      margin: auto;
      height: 6px;
      border-radius: 8px;
      background-color: $field-bg;
    }

    .progress-bar {
      transition: none !important;
    }

    .invalid .progress-bar {
      opacity: 0;
    }
  }
}

.dropzone-subtitle {
  font-size: 14px;
  margin-top: 20px;
  display: inline-block;
}

.btn-remove {
  padding: 0 10px;
  vertical-align: baseline;
  font-size: 11px;
  height: 18px;
  color: $gray;
}

.dropzone-artwork-container {
  .dropzone-uploaded-image {
    display: none;

    .image-input {
      width: 200px;
      height: 200px;
      background-size: cover;
      background-position: center;
    }
  }

  &.uploaded {
    .dropzone {
      display: none;
    }

    .dropzone-uploaded-image {
      display: block;
    }
  }
}

.dropzone-uploaded-image {
  .btn {
    padding: 0 !important;
  }
}

.modal-header {
  font-weight: 500;
  font-size: 16px;
  border-color: $gray-border;
}

.modal-footer {
  border-color: $gray-border;
}

.createModal {
  .modal-header {
    border-bottom: none;
    padding: 2rem 2rem 0;
  }

  .modal-body {
    padding-top: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .modal-footer {
    border-top: none;
    justify-content: center;
  }

  .progress {
    height: 4px;
  }
}

.create-icon {
  position: relative;
  display: inline-block;

  .icon-small {
    position: absolute;
    bottom: -8px;
    right: -8px;
  }

  &.var2 {
    .icon-small {
      position: absolute;
      bottom: 12px;
      right: -4px;
    }
  }
}

// upload

.upload-page {
  background: $white;

  .create-new-podcast {
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 76px);

    &.border-none {
      min-height: calc(100vh - 232px);
      justify-content: flex-start;
    }
  }

  .progress {
    height: 4px;
  }
}

.upload-produc-title {
  display: flex;
  align-items: center;

  &-icon {
    width: 33px;
    height: 33px;
    border-radius: 6px;
    overflow: hidden;
    margin-right: 15px;

    img {
      max-width: 100%;
      min-height: 100%;
    }
  }

  h4 {
    margin-bottom: 0 !important;
  }
}

// delete product

.delete-product {
  box-shadow: 0 0 10px rgba($dark, 0.04);
  overflow: hidden;
  border-radius: 12px;
  border: 1px solid $gray;
  max-width: 722px;
  width: 100%;

  &-body {
    padding: 40px 60px 20px 30px;
  }

  h3 {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 20px;
  }

  .form-label {
    color: $dark;
    margin-bottom: 20px;
  }

  .form-group {
    margin-bottom: 0;
  }

  .form-control {
    height: 50px;
  }

  &-item {
    display: flex;
    align-items: center;

    &:last-child {
      margin-bottom: 30px;
    }

    &-img {
      width: 100px;
      height: 100px;
      border-radius: 12px;
      overflow: hidden;
      margin-right: 18px;

      .cover-image {
        width: 100px;
        height: 100px;
      }

      img {
        max-width: 100%;
        min-height: 100%;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }

  &-footer {
    background: rgba($primary, .05);
    padding: 19px 30px 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.error {
  h3 {
    font-size: 26px;
    font-weight: 600;
  }

  p {
    font-size: 18px;
    font-weight: 500;
    color: $dark-text;
  }
}

.form-control {
  height: 48px;
  font-size: inherit !important;
  padding: 13px;

  &.form-control-sm,
  &.form-control-sm:focus {
    height: 32px;
  }
}

.custom-select.form-control-sm,
.custom-select.form-control-sm:focus {
  height: 32px;
  font-size: 0.925rem;
  padding: 0 .75rem;
}

textarea.form-control.form-control-sm {
  height: auto;
}

textarea.h-150 {
  height: 150px !important;
}

.episode-edit {
  background: $white;
  border-top: 1px solid $gray;
}

.episode-name {
  h4 {
    font-size: 14px;
    font-weight: 600;
  }

  span {
    font-size: 13px;
    color: $gray-text;
  }
}

.listeners-modal {
  form {
    justify-content: space-between;

    .form-group {
      width: calc(33.333% - 20px);

      span {
        color: $gray-text;
        font-size: 10px;
      }
    }
  }

  .modal-footer {
    justify-content: center;
    border-top: none;
  }
}

.listeners-edit {
  .search-row {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: $gray-bg;

    &-product {
      display: flex;
      align-items: center;

      .checkbox {
        margin-right: 40px;
      }

      h4 {
        font-size: 12px;
        color: $dark-text;
        margin-bottom: 0;
      }
    }

    &-navigation {
      display: flex;
      align-items: center;

      .pagination-info {
        display: block;
        width: 100%;
        min-width: 60px;
        margin-bottom: 0;
      }

      &-wrapper {
        display: flex;

        .search {
          width: 100%;
          margin-right: 40px;

          .MuiPaper-root,
          .makeStyles-root-9 {
            width: 100%;
          }
        }
      }
    }
  }

  &-row {
    display: flex;
    padding: 20px;

    .checkbox {
      margin-right: 40px;
    }
  }

  &-product {
    display: flex;
    align-items: center;

    &-icon {
      width: 50px;
      height: 50px;
      border-radius: 12px;
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
      }

      .cover-image {
        width: 100%;
        height: 100%;
      }
    }

    h4 {
      font-size: 14px;
      color: $dark-text;
    }
  }
}

.search-row-btn,
.listeners-edit .dropdown .btn,
.listeners-edit .btn.btn-icon.btn-sm {
  width: 25px;
  min-width: 25px;
  height: 25px;
  line-height: 25px;
  padding: 0 4px;
  border-radius: 4px;
  background: $white;
  margin-right: 7px;
  text-align: center;
  border: none;

  i {
    font-size: 10px;
  }
}

.welcome {
  h3 {
    font-size: 26px;
    margin-bottom: 14px;
  }

  p {
    font-size: 18px;
    margin-bottom: 25px;
  }

  .row {
    min-height: 100vh;
    align-items: center;
  }
}

.congrats-modal {
  text-align: center;

  .modal-body {
    padding-left: 60px;
    padding-right: 60px;
  }

  .modal-header {
    border-bottom: none;
  }

  .modal-footer {
    padding-top: 0;
    border-top: none;
    justify-content: center;
  }

  .form-group {
    text-align: left;
    width: 100%;
  }

  &-img {
    position: absolute;
    top: -75%;
    left: 50%;
    transform: translateX(-50%);
    width: 195px;
    height: 195px;
    background: $gray-bg;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &-wrapp {
      position: relative;
      margin-bottom: 15px;
      height: 135px;
    }

    img {}
  }
}

.upload-header-icon {
  display: flex;
  align-items: center;
  list-style: none;
  justify-content: center;
  margin: 0;
  padding: 25px;

  li {
    &:not(last-child) {
      margin-right: 22px;
    }
  }
}

@media (min-width: 600px) {
  .MuiTab-root {
    min-width: inherit !important;
  }
}

.MuiTab-root {
  //padding-left: 0 !important;
}

.PrivateTabIndicator-root-2 {
  height: 5px !important;
  bottom: -2px !important;
  border-radius: 4px;
}

#kt_content {
  .container {
    max-width: 100%;
  }
}

.header.header-fixed {
  z-index: 99999 !important;
}

.header-fixed.subheader-fixed .subheader {
  z-index: 9999 !important;
  box-shadow: none !important;
}

.subheader-solid {
  .sub-header-link {
    margin-right: 23px;
  }
}

.tabs-header-wrapper {
  position: relative;
  margin-bottom: 5px;
  height: 30px;

  .MuiTabs-root,
  .MuiTabs-fixed {
    overflow: inherit !important;
  }
}

.makeStyles-root-6 {
  background: transparent;
}

.modal-dialog {
  .dropzone.dropzone-default {
    max-width: 620px;
  }
}

.confirmModal,
.confirm-modal {
  padding: 0 !important;

  .modal-content {
    position: relative;
  }

  .close {
    position: absolute;
    right: 30px;
    top: 41px;
    width: 12px;
    height: 12px;
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;

    img {
      width: 12px;
    }
  }

  .modal-body {
    text-align: center;
    font-size: 14px;

    p,
    ul {
      margin-bottom: 19px;
    }

    ul {
      list-style-type: none;
      display: inline-block;
      padding: 0;
      text-align: left;
    }

    li {
      padding-left: 12px;
    }

    li::before {
      content: "";
      float: left;
      margin-left: -12px;
      margin-top: 9px;
      width: 4px;
      height: 4px;
      border-radius: 100px;
      background-color: $dark-text;
    }
  }

  .modal-footer {
    border: none;
    justify-content: center;
    padding-top: 0 !important;

    button {
      font-weight: 500;
      font-size: 14px;
      margin: 0 7px;
    }
  }

  &.children-in-footer .modal-footer {
    justify-content: start;

    & :first-child {
      flex: 1;
    }
  }
}

.already-exists-dialog {
  .modal-body {
    img {
      margin: 17px 0 0;
    }
  }

  .modal-footer {
    padding-bottom: 27px;

    button {
      display: block;
      margin: 0 auto 14px;
      max-width: 301px;
      white-space: no-wrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.upgrade-modal {
  .modal-dialog {
    max-width: 390px;
  }

  .modal-body {
    &>img {
      margin-top: 17px;
      margin-bottom: 33px;
    }

    .message {
      max-width: 260px;
      margin: 0 auto 27px;
    }

    button {
      font-weight: 500;
      font-size: 14px;
      margin: 0 7px;
    }

    .savings {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 13px;
      margin-bottom: 13px;

      .value {
        font-size: 48px;
        display: inline-block;
        position: relative;
        color: $primary;
        margin: 0 7px;
        vertical-align: sub;

        span {
          position: relative;
          z-index: 2;
        }

        &::before {
          content: "";
          position: absolute;
          z-index: 1;
          border: 2px solid $gray;
          border-radius: 100%;
          width: 106%;
          margin-left: -5%;
          height: 1.3em;
          transform: rotate(-14deg);
          margin-top: 1px;
        }
      }
    }

    .confirm-slider {
      position: relative;
      height: 40px;
      transition: height .2s;
      overflow: hidden;
      margin-bottom: 18px;

      &>div {
        position: absolute;
        width: 300%;
        left: 0;
        top: 0;
        transition: left .2s;
        display: flex;
        z-index: 1;
      }

      &>div>div {
        flex: 1;
      }

      &.show-confirm {
        height: 101px;
      }

      &.show-confirm>div {
        left: -100%;
      }

      &.show-success {
        height: 83px;
      }

      &.show-success>div {
        left: -200%;
      }

      .button {
        button {
          width: 100%;
          max-width: 278px;
        }
      }

      .alert {
        margin-bottom: 0;
      }

      .spinner {
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        pointer-events: none;
        background: rgba($white, .8);
        text-align: center;
        transition: opacity .1s;
      }

      .spinner::before {
        left: 50%;
        top: 50%;
        margin: -.75rem;
      }

      &.loading .spinner {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}

@media(min-width: 500px) {
  .upgrade-modal .modal-dialog {
    margin-right: 1.75rem;
  }
}

.image-input .image-input-wrapper {
  width: 180px !important;
  height: 180px !important;
}

.symbol.symbol-60.symbol-2by3 .symbol-label {
  width: 58px;
  height: 58px;
}

.symbol {
  margin-right: 12px;


  &>img {
    width: 100% !important;
    height: 100% !important;
    max-width: none !important;
    max-height: none !important;
  }
}

.header-fixed.subheader-fixed .subheader {
  position: fixed;
  height: 70px;
  top: 65px;
  left: 0;
  right: 0;
  transition: top 0.3s ease;
  z-index: 95;
  box-shadow: 0 10px 30px 0 rgba(82, 63, 105, 0.08);
  background: $white-bg;
  border-top: 1px solid $gray;
  margin: 0;
}

.dropdown-menu {
  z-index: 999999 !important;
}

.drag-wrapper {
  display: flex;
  align-items: center;
}

.drag-btn {
  height: 12px;
  position: relative;
  width: 8px;
  cursor: grab;
  margin-right: 12px;

  &:focus {
    cursor: grabbing;
  }

  span {
    position: absolute;
    width: 2px;
    height: 2px;
    background: $dark-text;
    border-radius: 50%;

    &:nth-child(1) {
      top: 0;
      left: 0;
    }

    &:nth-child(1) {
      top: 0;
      left: 0;
    }

    &:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }

    &:nth-child(3) {
      bottom: 0;
      left: 0;
    }

    &:nth-child(4) {
      top: 0;
      right: 0;
    }

    &:nth-child(5) {
      top: 50%;
      transform: translateY(-50%);
      right: 0;
    }

    &:nth-child(6) {
      bottom: 0;
      right: 0;
    }
  }
}

.PrivateTabIndicator-colorSecondary-4,
.MuiTabs-indicator {
  background-color: $primary !important;
  height: 4px !important;
}

.product-page-header {
  background: $white !important;
}

.select__product {
  margin-top: 40px;
  text-align: left;
  width: 100%;

  h4 {
    text-align: center;
    margin: 0;
  }

  .listeners-edit-row {
    padding-left: 38px;
  }
}

.modal-backdrop {
  z-index: 99999;
}

.modal {
  z-index: 999999;
}

.PhoneInputInput {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $dark-text;
  background: $white-bg;
  background-clip: padding-box;
  border: 1px solid $field-bg;
  border-radius: 0.42rem;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 46px;
}

.modal-form-top {
  justify-content: space-between;

  .form-group {
    width: calc(33.3333% - 10px);
  }
}

.Toastify__toast-container {
  z-index: 99999999;

  .Toastify__toast {
    font-family: inherit;
  }

  .Toastify__toast-body {
    a {
      color: white !important;
      text-decoration: underline !important;
    }
  }
}

.link-muted-underline {
  color: $gray-600;
  text-decoration: underline;
  display: inline-block;

  &:hover,
  &:focus {
    color: $gray-400;
    text-decoration: underline !important;
  }
}

.btn.disabled,
.btn[disabled],
.btn.btn-primary.disabled,
.btn.btn-primary[disabled],
.btn.btn-secondary.disabled,
.btn.btn-secondary[disabled],
.btn.btn-primary-light.disabled,
.btn.btn-primary-light[disabled],
.btn.btn-primary-transparent[disabled],
.btn.btn-primary-transparent.disabled {
  color: $btn-text;
  background-color: $gray-bg;
  border-color: $gray-bg;
}

.btn-primary .svg-icon [fill]:not(g) {
  fill: #fff;
}

.btn-primary-transparent .svg-icon [fill]:not(g) {
  fill: $primary;
}

.btn-quiet {
  background: transparent !important;
  border: 2px solid $gray-border !important;
  color: $gray-text;
}

.btn-lg {}

.btn-sm {
  height: 32px !important;
  font-size: 0.925rem !important;
  padding: 0 .65rem !important;
}

a.btn-lg {
  line-height: 48px;
}

.btn-quiet:hover,
.btn-quiet:active,
.btn-quiet:focus {
  color: $dark-text !important;
}

.btn.btn-transparent,
.btn.btn-primary-transparent {
  background-color: transparent !important;
  padding: 0 !important;
  border: none !important;

  &.disabled,
  &[disabled] {
    background-color: transparent !important;
  }
}

.btn-primary-transparent {
  color: $primary !important;
  font-weight: 500;

  svg {
    margin-top: -1px;
    margin-right: 4px;
  }
}

.dropdown-menu {
  width: auto !important;
  box-shadow: 0px 7px 18px 0px rgba(0, 0, 0, 0.09);
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid $gray;
  padding: 10px;

  &.show {
    margin: 5px 0 !important;
  }
}

.dropdown-toggle.form-control {
  color: inherit !important;
  font-size: 14px !important;
  padding: 0.65rem 1rem;
  background-color: $field-bg !important;

  &::after {
    color: inherit !important;
  }
}

.fixed-dropdown {
  .dropdown-menu {
    display: block !important;
    position: fixed !important;
  }
}

.table-options-dropdown {
  .dropdown-toggle {
    background: transparent !important;
    border: none;
    color: inherit !important;
    padding: 8px !important;
    height: auto !important;

    &::after {
      display: none;
    }

    svg,
    svg * {
      transition: fill .2s, stroke .2s;
    }

    &:hover {
      background: transparent !important;

      svg [fill] {
        fill: $primary;
      }
    }
  }
}

.dropdown-menu {
  .separator {
    height: 1px;
    margin: 9px 0 !important;
    border: none;
    background-color: $gray;
  }
}

.dropdown-item {
  font-size: 14px;
  align-items: center;
  padding: .6rem;
  color: inherit !important;
  text-transform: none !important;
  border-radius: 6px;
  transition: color .2s, background-color .2s;

  &:last-child {
    border-bottom: none;
  }

  img,
  svg {
    max-width: 18px;
    max-height: 18px;
    display: inline-block;
  }

  svg,
  svg * {
    transition: fill .2s, stroke .2s;
  }

  svg [fill] {
  }

  svg [stroke] {
  }

  &:hover svg [fill] {
    fill: $primary !important;
  }

  i {
    color: inherit;
  }

  .icon {
    width: 29px;
  }

  a {
    color: inherit !important;
    background-color: transparent !important;
  }

  &:focus,
  &:active,
  &:hover {
    color: inherit !important;
    background-color: $gray-bg !important;

    strong {
      color: $primary;
    }
  }

  &.active {
    color: inherit !important;
    background-color: $gray-bg !important;

    strong {
      color: $primary;
    }

    svg [fill] {
      fill: $primary;
    }
  }
}

.dark-icons {
  .dropdown-item {
    svg [fill] {
      fill: currentColor;
    }

    svg [stroke] {
      stroke: currentColor;
    }
  }
}

.aside-menu .menu-nav>.menu-section .menu-text {
  font-weight: 800 !important;
}

.menu-item-submenu {
  margin-bottom: 8px !important;
}

.image-input.image-input-outline .image-input-wrapper {
  box-shadow: none !important;
}

.submenu-link {
  .menu-link {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    min-height: auto !important;

    svg,
    path {
      transition: fill 0.5s ease;
    }

    &:hover {
      background: transparent !important;

      svg,
      path {
        transition: fill 0.3s ease;
        fill: $primary !important;
      }
    }

    &::before {
      display: none;
    }
  }
}

.title-double {
  .title {
    margin-bottom: 0;
  }
}

.product-setting {
  .title-double {
    .title {
      font-size: 1.55rem;
    }
  }
}

.aside-menu .menu-nav li {
  &:nth-child(2) {
    .menu-icon svg {
      width: 30px !important;
      height: 30px !important;
    }
  }
}

.HorizontalNonLinearStepper-instructions-4 {
  margin-top: 0 !important;
}

.card-element {
  min-height: calc(5vh - 20px);
}

.steps-block {
  .MuiStep-root {
    &.MuiStep-horizontal {
      padding-left: 0;
    }
  }

  .MuiStepper-root {
    position: sticky;

    h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: $dark-text;
      width: 270px;
      margin-top: 31px;
      margin-bottom: 53px;
    }
  }
}

.upload-complete {
  h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
  }

  .importer {
    &-item {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;

      h4 {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;

        &.color-primary {
          color: $green;
        }

        &.color-warning {
          color: $red;
        }
      }

      &:first-child {
        margin-bottom: 100px;
      }
    }

    &-list {
      list-style: none;
      padding: 0;
      margin: 0;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;

      li {
        margin-top: 16px;
        color: $gray-text;
      }
    }
  }
}

.gray-text-small {
  color: $gray-600;
  font-size: 12px;
}

.text-inline-block {
  flex: 2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  padding-top: 13px;
}

.form-control-width-sm {
  width: 81px !important;
}

.input-row>* {
  margin-right: 16px;

  &>:last-child {
    margin-right: 0;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.custom-select {
  box-shadow: none !important;
}

.upgrade-link {
  color: $primary;
  text-decoration: underline !important;

  &:hover {
    color: $primary-hover;
    text-decoration: underline !important;
  }
}

.badge,
.blue-badge,
.yellow-badge,
.upgrade-link.badge,
.gray-badge,
.red-badge,
.green-badge {
  line-height: 23px;
  white-space: nowrap;
  border-radius: 4px;
  padding: 0 9px;
  font-size: 11px;
  display: inline-block;
  background-color: $gray-bg;
  vertical-align: middle;
  height: 26px;
  line-height: 26px;
  margin-top: -4px;
  max-width: 265px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: none !important;
  color: inherit !important;

  &.lg {
    font-size: inherit;
    margin-top: 0;
    font-weight: normal;
  }

  small {
    font-size: inherit;
    display: inline-block;
    margin-left: 4px;
    color: $text-muted;
  }

  &.on-hold-badge {
    margin-top: 0;

    .dot {
      display: inline-block;
      width: 6px;
      height: 6px;
      background: #D25D4A;
      border-radius: 100%;
      margin-right: 4px;
      vertical-align: middle;
    }
  }
}

a.gray-badge:hover {
  color: $gray-600 !important;
}

.gray-badge {
  color: $gray-text !important;
}

.blue-badge {
  background-color: $primary-light;
  color: $primary !important;

  [fill] {
    fill: $primary;
  }
}

.beta-badge {
  background-color: $purple;
  color: $white !important;
  text-transform: uppercase;
  vertical-align: bottom;
  height: 20px;
  line-height: 19px;
  font-weight: 500;
  margin-left: 4px;

  [fill] {
    fill: $white;
  }
}

.yellow-badge {
  background-color: $yellow-light;
  color: $yellow !important;

  [fill] {
    fill: $yellow;
  }
}

.red-badge {
  background-color: $red-light;
  color: $red !important;

  [fill] {
    fill: $red;
  }
}

.green-badge {
  background-color: $green-light;
  color: $green !important;

  [fill] {
    fill: $green;
  }
}

.challenge-expires-badge,
.trial-ends-badge {
  font-size: 14px;
  margin-right: 15px;
  margin-bottom: 0;
  border-radius: 10px;
  height: auto;
  padding-top: 3px;
  padding-bottom: 3px;

  svg {
    margin-top: -3px;
  }
}

.trial-ends-badge {
  cursor: pointer;
}

.upgrade-link.badge {
  text-decoration: none !important;
  font-size: 12px;
  border-radius: 100px;
  background-color: $yellow-light;
  color: $yellow !important;
  border: 1px solid $yellow-border;

  svg {
    display: inline-block;
    margin-right: 7px;
    vertical-align: sub;
    margin-left: -3px;
  }
}

.rdt.input-date {
  margin-bottom: 14px;
  min-width: 180px;

  .form-control {
    margin: 0 !important;
    width: 100%;
  }

  .rdtPicker {
    * {
      font-size: inherit !important;
      line-height: inherit;
      height: auto;
    }

    .rdtSwitch,
    th {
      font-weight: 600 !important;
    }

    .rdtPrev,
    .rdtNext {
      font-weight: normal !important;
      font-size: 25px !important;
    }

    .rdtTime {
      padding-top: 10px;
    }
    
    .rdtCounter {
      .rdtBtn {
        font-size: 0 !important;

        &::after {
          height: 29px;
          display: block;
          font-size: 25px !important;   
        }

        &:first-child::after {
          content: "⌃";       
        }

        &:last-child::after {
          content: "⌃";
          rotate: 180deg;
        }
      }
    }
      
    .rdtCounterSeparator {
      padding-top: 37px;
    }
    
    th,
    td,
    .rdtBtn {
      border-radius: 6px;
      transition: background .3s;
      padding: 4px;
    }
  }

  &.open-up {
    .rdtPicker {
      bottom: 48px;
      border-radius: 6px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgb(0, 0, 0, .1)!important;
      margin-bottom: 6px;
    }
  }
}

@media (min-width: 576px) {
  .p-sm-30-episode {
    padding: 2.5rem 5rem !important;
  }
}

@media (max-width: 1300px) {

  .custom-page .steps-block .makeStyles-root-1,
  .custom-page .steps-block>div,
  .product-setting .steps-block .makeStyles-root-1,
  .product-setting .steps-block>div {
    flex-direction: column;
  }

  .makeStyles-root-1 {
    width: 100%;
  }

  .custom-page .MuiStepper-root,
  .product-setting .MuiStepper-root {
    width: 100%;
    margin-bottom: 30px;
  }

  .custom-page .MuiStepper-root~div,
  .product-setting .MuiStepper-root~div {
    width: 100%;
  }

  .custom-page .MuiStepper-root,
  .product-setting .MuiStepper-root {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .custom-page .MuiStepButton-root,
  .product-setting .MuiStepButton-root {
    margin-bottom: 10px;
  }

  .steps-block {
    .MuiStepper-root {
      position: static;
      top: auto;
    }

    .HorizontalNonLinearStepper-root-5,
    >div {
      width: 100%;
    }
  }
}


@media (max-width: 1100px) {
  .checkbox-custom {
    flex-wrap: wrap;
    margin-top: -20px;

    &-item {
      margin-top: 20px;
      width: calc(50% - 20px);
    }
  }
}

@media (max-width: 991px) {
  .product-setting {
    margin-top: 30px;
  }

  .header-fixed.subheader-fixed .subheader {
    top: 50px;
  }

  .product-setting .MuiAppBar-root {
    top: 104px;
    left: 0;
  }

  .title-double {
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  body {
    min-width: 320px;
  }

  .checkbox-custom {
    flex-wrap: wrap;
    margin-top: -20px;

    &-item {
      margin-top: 20px;
      width: calc(100% - 20px);
    }
  }

  .header-page {
    padding: 20px;
  }

  .header-page-title .btn-close {
    margin-right: 10px;
  }

  .navigation-menu-custom {
    li:not(:last-child) {
      margin-right: 10px;
    }

    &-link {
      padding: 0 10px;
      font-size: 10px;
    }
  }

  .navigation-menu-custom {
    li:not(:last-child) {
      margin-right: 10px;
    }

    &-link {
      padding: 0 10px;
      font-size: 10px;
    }
  }

  .create-new-podcast-main,
  .create-new-podcast-row,
  .create-new-podcast-bottom {
    width: 100%;
  }

  .create-new-podcast-list,
  .create-new-podcast-bottom-content {
    padding-left: 0;
  }

  .create-new-podcast-main-logo {
    flex-direction: column;

    img {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }

  .HorizontalNonLinearStepper-root-1 {
    width: 100% !important;
  }
}

@media (max-width: 668px) {
  .navigation-menu-custom {
    &-link {
      span {
        display: none;
      }
    }
  }

  .custom-select-wrapp {
    flex-wrap: wrap;

    p {
      margin-top: 15px;
      width: 100%;
    }
  }

  .delete-product-body {
    padding: 30px 20px 20px 20px;
  }

  .delete-product-footer {
    padding: 19px 20px 18px;
  }

  .product-table {
    overflow-x: auto;

    table {
      width: 668px;
    }
  }
}

@media (max-width: 500px) {
  .btn {
    padding: 0.5rem 1rem;
    font-size: 10px;
  }

  .product-setting .MuiTabs-flexContainer button:not(last-child) {
    margin-right: 8px;
  }

  .MuiTab-root {
    padding: 6px 4px !important;
  }
}

.mtm-70 {
  margin-top: -70px;
}

.invalid-feedback-copy,
.field-error {
  width: 100%;
  margin-top: .75rem;
  color: $red;
}

.install-page {
  text-align: center;
  font-size: 14px;
  padding: 60px 25px;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 470px;
  margin: auto;
  width: 100%;

  .logo {
    width: 114px;
  }

  .cover-image {
    width: 150px;
    height: 150px;
    margin: 20px auto;
    border-radius: 16px;
  }

  h3 {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 0;
  }

  .error {
    padding: 200px 0;
    font-weight: 600;
  }

  &.desktop {
    padding: 60px 25px;
    font-weight: 500;
    font-size: 16px;

    .logo {
      margin-bottom: 48px;
      margin-left: auto;
      margin-right: auto;
    }

    p {
      margin-bottom: 28px;

      a {
        color: inherit;
      }
    }

    canvas {
      mix-blend-mode: multiply;
      margin-bottom: 28px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.btn-install-player {
  display: block;
  width: 100%;
  max-width: 250px;
  margin: 0 auto 14px;
  font-weight: 500 !important;
  font-size: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    width: 18px;
    margin-right: 12px;

    path {
      fill: white;
    }
  }
}

.allow-sms-notifications-modal {
  top: auto;
  bottom: 0;
  height: auto;
  overflow: visible !important;

  .modal-dialog {
    margin: 0;
    transform: translate(0, 50px) !important;

    .modal-content {
      border-radius: 0;
      box-shadow: 0 -0.25rem 0.5rem rgba(0, 0, 0, .1);

      .modal-body {
        padding-bottom: 1rem;
      }

      .modal-footer {
        border-top: none;
        padding-top: 0;
      }
    }
  }

  &.show .modal-dialog {
    transform: translate(0, 0) !important;
  }
}

.feed-desktop-message {
  text-align: center;
  font-size: 16px;

  .need-help {
    margin-bottom: 0;
    font-size: .9em;
    font-weight: 100;
  }

  a {
    color: inherit !important;
    text-decoration: underline;
  }
}

.install_player_button {
  background-color: $primary;
  border-radius: 10px;
  width: 80%;
  min-height: 3em;
  margin: 0 auto 10px auto;
  font-weight: 700;
  cursor: pointer;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.icon-transform-desc {
  cursor: pointer;
  transform: rotate(180deg);
}

.rdt {
  max-width: 200px;

  &.w-auto {
    max-width: none;
  }
}

.product-settings-save-button {
  border-radius: 6px;
  padding: 10px 28px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: $white;
  border: none;
  box-shadow: none;
  background: $primary;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;

  &:disabled {
    opacity: 0.56;
  }
}

.product-settings-rollback-button {
  margin-right: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: $gray-text;
  padding: 10px 28px;
  background-color: transparent;
  border: none;
  box-shadow: none;
}

// New narrow cards

.card-narrow {
  max-width: 755px;
  margin-left: auto;
  margin-right: auto;
}

// Profile settings

.dropzone-avatar .dropzone-uploaded-image .image-input {
  width: 106px;
  height: 106px;
}

.dropzone-xs .dropzone {
  max-width: 211px;

  &>div {
    padding: 18px;
  }

  h3 {
    font-size: inherit;
  }
}

.profile-overview-user-logo {
  max-width: 130px;
  max-height: 130px;
  border-radius: 6px;
}

// Forms (normalization)

.form-group {
  margin-bottom: 2.75rem;

  &>small {
    font-size: 12px;
    margin-top: .49em;
    display: block;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.form-label {
  font-weight: 600;
  margin-bottom: 1rem;
  display: block;
  
  em {
    color: $red;
    font-style: normal;
  }

  small,
  .help-text {
    display: block;
    color: #3f4254;
    font-size: inherit;
    font-weight: normal;
    margin-top: .5rem;
    display: block;

    &.inline {
      display: inline-block;
      margin-left: 5px;
      margin-top: 0;
    }
  }

  strong {
    font-weight: 600;
  }
}

.form-control,
.custom-select,
.form-control:focus,
.custom-select:focus {
  background-color: $field-bg !important;
  border-color: $field-border !important;
  color: $dark-text;
  height: 48px;
  font-family: inherit;
}

.form-control.disabled,
.form-control[disabled],
.custom-select.disabled,
.custom-select[disabled] {
  color: #CBCBCB !important;
  background-color: #F1F3FB !important;
  background-blend-mode: color-burn;
}

::placeholder {
  color: #CBCBCB !important;
}

textarea.form-control:focus {
  height: auto;
}

.form-control-mw-170 {
  max-width: 170px;
}

.mw-340 {
  max-width: 340px;
}

.form-control-silent {
  padding-left: 0;
  padding-right: 0;
  background-color: transparent !important;
  border-color: transparent !important;
  cursor: default;
}

.form-control.PhoneInput {
  display: flex;

  .PhoneInputInput {
    background: transparent !important;
    border: none !important;
    outline: none !important;
    padding: 0;
    padding-left: 0.5rem;
    padding-top: 0.3rem;
  }
}

.custom-select {
  background-image: URL("data:image/svg+xml;utf8,<svg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M4.5 6.5L7.5 9.5L10.5 6.5' stroke='%233f4254' stroke-linecap='square'/></svg>");
  background-size: 22px;
  background-position: center right 7px;
  padding-right: 2.6rem !important;
}

.input-group-text {
  border: none;
  background-color: $field-bg;
  z-index: 10;
}

.input-group-append {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 1px solid #dbdfe9;
  border-left: none;
}

.input-group-prepend {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border: 1px solid #dbdfe9;
  border-right: none;
}

.input-group .input-group-prepend + .form-control.is-valid, .input-group .input-group-prepend + .form-control.is-invalid {
  margin: 0 !important;
}

.input-group .svg-icon {
  width: 1.3em;
  height: 1.3em;
}

.btn {
  font-weight: 500;
  font-size: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 48px;
  padding: 0 1.5rem;
  cursor: pointer;
  flex-shrink: 0;
}

a.btn {
  line-height: 44px;
}

a.btn-sm {
  line-height: 29px;
}

.btn.btn-white.btn-shadow {
  box-shadow: 0px 6px 11px 0px rgba(24, 28, 50, .15) !important;
}

.btn.btn-secondary {
  background-color: #E7F1F5 !important;
  border-color: #E7F1F5 !important;

  &,
  &::after {
    color: $btn-text !important;
  }
}

.btn.btn-gray {
  background-color: $gray !important;
  border-color: $gray !important;

  &,
  &::after {
    color: $btn-text !important;
  }
}

.btn.btn-secondary:hover,
.btn.btn-secondary:focus {
  background-color: #f5fbfe !important;
  border-color: #f5fbfe !important;
}

.btn.btn-gray:hover,
.btn.btn-gray:focus {
  background-color: $gray-hover !important;
  border-color: $gray-hover !important;
}

.btn.btn-dark-gray {
  background-color: $primary-darker !important;
  border-color: $primary-darker !important;

  &,
  &::after {
    color: white !important;
  }
}

.btn.btn-dark-gray:hover,
.btn.btn-dark-gray:focus {
  background-color: $dark !important;
  border-color: $dark !important;
}

.btn.loading {
  color: transparent !important;
  pointer-events: none;

  &.spinner::before {
    left: 50%;
    margin-left: -.5rem;
    margin-top: -.5rem;
    width: 1rem;
    height: 1rem;
    border: 2px solid rgba($white, .9);
    border-right: 2px solid transparent;
  }

  &.dropdown-toggle.nav-link::before,
  &.dropdown-toggle.btn::before {
    display: block !important;
    content: "" !important;
    position: absolute !important;
  }

  &.spinner.spinner-dark::before,
  &.btn-secondary.spinner::before {
    border: 2px solid rgba($gray-600, .9);
    border-right: 2px solid transparent;
  }

  &.btn-primary-light.spinner::before {
    border: 2px solid rgba($primary, .9);
    border-right-color: transparent;
  }

  svg,
  i {
    opacity: 0;
  }
}

.btn-two-lines {
  line-height: 17px;
  padding-top: .7em !important;
  padding-bottom: .7em !important;
  height: auto !important;

  small {
    color: #707695;
    font-size: 11px;
  }

  &.btn-lg small {
    font-size: 13px;
  }

  &.btn-primary small {
    color: #c4deec;
  }

  &.btn-quiet {
    small {
      color: #bbbfce;
    }

    &:hover small {
      color: #707695;
    }
  }
}

.btn .svg-icon,
.btn-transparent .svg-icon {
  height: 18px !important;
  width: 18px !important;
  vertical-align: sub;
}

.btn-primary-light {
  color: $primary;
  background-color: $primary-light;

  .svg-icon [fill] {
    fill: $primary;
  }

  &:hover,
  &:focus {
    color: $primary;
    background-color: $primary-light-hover;
  }
}

.btn-group {
  display: block;

  .btn {
    background-color: $field-bg !important;
    border-color: $field-border;
    color: inherit;
    font-weight: normal;
    font-size: inherit;

    &::before {
      content: "";
      display: inline-block;
      vertical-align: text-top;
      width: 18px;
      height: 18px;
      margin-right: 10px;
      border: 2px solid $field-border;
      border-radius: 100%;
      background-color: transparent;
      transition: background-color .2s, border-color .2s;
      margin-top: 1px;
    }
  }

  .btn:hover,
  .btn:focus {
    background-color: $primary-light !important;
  }

  .btn.btn-active {
    background-color: $primary-light !important;
    border-color: $primary !important;
    color: $primary !important;
    z-index: 2;

    &::before {
      border-color: $primary;
      background-color: white;
      border-width: 4px;
    }
  }
}

.editor-wrapper {
  max-width: 695px;
  border: 1px solid $field-border;
  background-color: $field-bg;
  border-radius: 12px;
  padding: 0;

  &.fw {
    max-width: 100%;
  }

  .tox {
    * {
      border-color: $field-bg !important;
      background: $field-bg !important;
    }

    .tox-statusbar {
      display: none;
    }

    .tox-editor-header {
      padding-top: 23px !important;
      padding-bottom: 20px !important;
      padding-right: 23px !important;
      padding-left: 23px !important;

      .tox-anchorbar {
        background: #dcdde1 !important;
        height: 1px;
        position: absolute;
        left: 23px;
        right: 23px;
        top: 70px;
      }
    }

    .tox-toolbar,
    .tox-toolbar__overflow,
    .tox-toolbar__primary {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      position: relative;

      &::before {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        width: 100%;
        background: $field-bg;
        content: "";
      }
    }

    .tox-toolbar__group {
      padding: 0 10px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    .tox-tbtn,
    .tox-split-button {
      margin: 0 6px;
      padding: 0;
      width: auto;

      * {
        cursor: pointer !important;
      }

      &.tox-tbtn--disabled * {
        cursor: not-allowed !important;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
        ;
      }

      span {
        font-size: 12px !important;
        font-family: Poppins, Helvetica, sans-serif !important;
        font-weight: 500 !important;
        color: $gray-600 !important;
        transition: color .2s;
      }

      .tox-tbtn__select-label {
        margin-left: 3px;
        margin-right: 0;
      }

      svg {
        path {
          transition: fill .2s;
          fill: $toolbar-icon;
        }
      }

      &:hover {
        span {
          color: $new-dark !important;
        }

        svg path {
          fill: $new-dark !important;
        }
      }
    }
  }

  .tox-tinymce {
    width: 100%;
    height: 238px !important;
    border: none;
  }

  .tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type) {
    border-color: $field-bg;
  }

  .tox-editor-header {
    box-shadow: none !important;
  }
}

input.hide-spinner::-webkit-outer-spin-button,
input.hide-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.hide-spinner {
  -moz-appearance: textfield;
}

.form-check {
  display: block;
  padding-left: 26px;
  cursor: pointer;

  label {
    font-weight: normal !important;
    font-size: inherit !important;
    margin: 0 !important;
    cursor: pointer;
  }

  .form-check-input {
    width: 18px !important;
    height: 18px !important;
    padding: 0 !important;
    margin-top: 1px;
    vertical-align: top;
    background-color: $field-border;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: none;
    appearance: none;
    color-adjust: exact;
    float: left;
    margin-left: -26px;
    cursor: pointer;

    &:checked {
      background-color: $primary;

      &[type=radio] {
        background-image: URL("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
      }

      &[type=checkbox] {
        background-image: URL("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
      }
    }

    &[type=radio] {
      border-radius: 50%;
    }

    &[type=checkbox] {
      border-radius: 3px;
    }
  }
}

// Styles imported (with adaptations) from the new version of Metronic

.table {

  td,
  th {
    vertical-align: middle !important;
  }

  a.text-inherit {
    font-weight: normal;
  }
}

.text-decoration-underline {
  text-decoration: underline;
}

.py-20 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.symbol-50px {
  width: 50px;
  height: 50px;
}

.gray-bg {
  background-color: $gray-bg;
}

.spinner-full {
  width: 100;
  padding-top: 100px;
  padding-bottom: 100px;

  &::before {
    width: 2rem;
    height: 2rem;
    margin-top: -1rem;
    margin-left: -1rem;
    left: 50%;
  }
}

.spinner-blue {
  &::before {
    width: 50px;
    height: 50px;
    margin-top: -25px;
    margin-left: -25px;
    left: 50%;
    border: 5px solid $primary;
    border-right-color: transparent;
  }

  &.spinner-sm::before {
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    border-width: 3px;
  }
}

.spinner.spinner-center::before {
  left: 50%;
  margin-left: -0.75rem;
}

.form-switch {
  padding-left: 2.5em;

  .form-check-input {
    width: 2em !important;
    margin-left: -2.5em;
    background-image: URL("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em !important;
    transition: background-position .15s ease-in-out;

    &:checked {
      background-position: right center;
      background-image: URL("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
    }
  }
}

.row.g-2 {
  margin-left: -6px;
  margin-right: -6px;

  &>div {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.page-link {
  border-color: $gray;
}

.mw0 {
  min-width: 0;
  width: 100%;
}

//This is actually the regular .container which got changed with a new name
@media (min-width: 576px) {
  .container-regular {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-regular {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-regular {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-regular {
    max-width: 2140px;
  }
}

@media (min-width: 1400px) {
  .container-regular {
    max-width: 1320px;
  }
}

.container-regular {
  margin-right: auto;
  margin-left: auto;
  padding-left: 13px;
  padding-right: 13px;
}

.card {
  font-size: 14px;
  margin-bottom: 1.25rem;
  width: 100%;

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    min-height: 70px;
    padding: 1.845rem 2.25rem;
    background-color: transparent;
    border-bottom: 1px solid $gray-border;

    .h3,
    h3 {
      font-size: 16px;
    }

    p {
      margin-top: 8px;
      margin-bottom: 0;
    }
  }

  .card-footer {
    background-color: transparent !important;
    border-top: 1px solid $gray-border;
  }

  &.card-empty {
    .card-header {
      border-bottom: none;
    }

    .card-body {
      display: none;
    }
  }

  .card-body {
    width: 100%;

    &>:last-child {
      margin-bottom: 0 !important;
    }
  }
}

@media (min-width: 1200px) {
  .card {
    margin-bottom: 2.5rem !important;
  }
}

.alert {
  border: 1px dashed #000;
  padding: 1rem;
  font-weight: normal;

  &.alert-warning {
    border-color: $yellow-border;
    background: $yellow-light;
    color: $dark-text;

    svg [fill]:not(g) {
      fill: $yellow-border;
    }

    h4 {
      color: $dark-text;
    }
  }

  &.alert-success {
    border-color: $green-border;
    background: $green-light;
    color: $dark-text;

    svg [fill]:not(g) {
      fill: $green-border;
    }

    h4 {
      color: $dark-text;
    }
  }

  &.alert-white {
    border-color: transparent;
    background: #fff;
    color: $gray-text;

    svg [fill]:not(g) {
      fill: $gray;
    }

    h4 {
      color: $dark-text;
    }
  }

  h4 {
    font-weight: 700;
    font-size: inherit;
    margin-bottom: .3em;
  }

  .svg-icon {
    margin-right: 1rem;
    width: 30px;
    height: 30px;
  }
}

.border {
  border-color: $gray-border !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-gray-300 {
  border-color: $gray-border !important;
}

.border-transparent {
  border-color: transparent !important;
}

.text-gray-600 {
  color: $gray-600;
}

.text-gray-400,
.text-gray {
  color: $gray-text !important;
}

.ml-2 {
  margin-left: .5rem !important;
}

.me-1 {
  margin-right: .25rem !important;
}

.me-2 {
  margin-right: .5rem !important;
}

.me-3 {
  margin-right: .75rem !important;
}

.me-4 {
  margin-right: 1rem !important;
}

.me-5 {
  margin-right: 1.25rem !important;
}

.me-6 {
  margin-right: 1.5rem !important;
}

.me-4 {
  margin-right: 1rem !important;
}

.me-7 {
  margin-right: 1.75rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.separator {
  display: block;
  height: 0;
  border-bottom: 1px dashed $gray-border;
  margin: 32px 0 !important;

  &:last-child {
    display: none;
  }
}

.pe-2 {
  padding-right: .5rem !important;
}

.fw-normal {
  font-weight: 500 !important;
}

.fw-bold,
.fw-semibold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: 600 !important;
}

.fs-1 {
  font-size: 1rem !important;
}

.fs-2 {
  font-size: 1.4rem !important;
}

.fs-5 {
  font-size: 1.15rem !important;
}

.fs-6 {
  font-size: 1.075rem !important;
}

.fs-7 {
  font-size: 1rem !important;
  font-weight: 500;
}

.nav-stretch {
  align-items: stretch;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .nav-item {
    display: flex;
    align-items: stretch;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.nav-line-tabs {
  .nav-item {
    .nav-link {
      padding: 0;
      transition: color .2s ease, background-color .2s ease, border-color .2s ease;
      border-bottom: 3px solid transparent;
      white-space: nowrap;
      color: $gray-text;

      &.disabled {
        color: $disabled-text !important;
      }

      &:hover:not(.disabled),
      &.active {
        color: $primary;
        border-color: $primary;
      }
    }
  }
}

.is-disabled {
  opacity: .4;
}

.btn[disabled],
.btn.disabled,
a[disabled],
a.disabled {
  pointer-events: none;
  background-color: #F1F3FB !important;
  opacity: 1 !important;
  color: #CBCBCB !important;
  border-color: transparent !important;

  .svg-icon [fill] {
    fill: #cbcbcb;
  }
}

.dropdown-item.disabled,
.dropdown-item[disabled] {
  cursor: not-allowed;
  color: $dark-text;
  background: transparent;
  opacity: .4;
}

.dropzone-disabled {
  opacity: .4;
  pointer-events: none;
}

.symbol.symbol-100px {
  width: 100px;
  height: 100px;
}

.svg-icon {
  line-height: 1;

  &.svg-icon-2 {
    height: 1rem !important;
    width: 1rem !important;
    vertical-align: baseline !important;
  }

  &.svg-icon-3 {
    height: 1.35rem !important;
    width: 1.35rem !important;
  }

  &.svg-icon-success [fill]:not(ermanent):not(g) {
    fill: $green;
  }

  &.svg-icon-danger [fill]:not(.permanent):not(g) {
    fill: $red;
  }

  &.svg-icon-social [fill] {
    fill: #a1a5b7;
  }
}

@media (min-width: 1200px) {}

@media (min-width: 992px) {
  .symbol.symbol-lg-150px {
    width: 150px;
    height: 150px;
  }
}

.dropzone-message {
  .dropzone-sub-description {
    display: flex;
    margin-top: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    color: #7E8299;
  }
}

.switch-inline-container {
  display: inline-block;
  vertical-align: middle;
  margin-left: 30px;
  white-space: nowrap;
  font-size: 13px;
  color: $gray-text;
  font-weight: normal;

  .status-label-live {
    color: $green;
  }
}

.switch-container {
  .status-label {
    &-live {
      color: $green;
    }
  }

  .status-alert {
    color: $gray-text;
    background-color: $gray-bg;
    border-radius: 6px;
    padding: .5rem 1.1rem;
    margin-left: 1.6rem;
    font-size: 13px;
    white-space: nowrap;

    img {
      margin-right: 1.1rem;
    }

    &-live {
      color: $green;
      background-color: $green-light;
    }
  }

  .status-alert-btn {
    margin-left: 8px;
    height: 34px;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 34px;
  }
}

///Sales page settings

.dropzone-review-cover {
  .dropzone {
    max-width: 100px;
    border: none;
    background: transparent;
    overflow: visible;

    &>div {
      padding: 0;
    }

    .dropzone-content,
    .dropzone-progress-container {
      display: none !important;
    }
  }

  .dropzone-uploaded-image {
    display: block !important;
    position: relative;

    &::after {
      content: "";
      background: rgba(255, 255, 255, .75);
      opacity: 0;
      transition: opacity .1s;
      pointer-events: none;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
    }

    &::before {
      opacity: 0;
      transition: opacity .1s;
      pointer-events: none;
      z-index: 3;
      margin-left: -.75rem;
      left: 50%;
    }

    .image-input {
      width: 100px;
      height: 100px;
      position: relative;
      z-index: 1;
    }
  }

  .dropzone-has-progress {
    .dropzone-uploaded-image {

      &::after,
      &::before {
        opacity: 1;
      }

      .btn {
        display: none;
      }
    }
  }
}

.star-gray,
.star-yellow {
  width: 23px;
  margin-right: 2px;
  vertical-align: middle;
  margin-top: 0;

  path {
    fill: $gray;
  }
}

.star-yellow path {
  fill: $yellow;
}

.sales-page-settings {
  .cover-image {
    width: 132px;
    height: 132px;
    margin-right: 25px;
    border-radius: 8px;
    overflow: hidden;
  }

  .product-description {
    max-width: 50%;
  }
}

.review-item,
.faq-item,
.tracking-item {
  border-bottom: 1px dashed $gray-border;
  padding-bottom: 2.25rem;
  margin-bottom: 2.25rem;

  &>.d-flex {
    flex-wrap: nowrap;
  }

  .btn-expand-col {
    flex: 0 0 41px;
  }

  .btn-expand {
    background-color: $gray;
    border: none;
    color: $gray-text;
    font-size: 14px;
    font-weight: normal;
    width: 16px;
    height: 16px;
    line-height: 16px;
    border-radius: 2px;
    padding: 0;
    transition: color .2s, background-color .2s;

    &::after {
      content: "+";
    }
  }

  .logo {
    width: 18px;
    height: 18px;
  }

  .check-icon {
    width: 18px;
    height: 18px;

    &.fill-gray [fill] {
      fill: $gray-300;
    }
  }

  .review-cover {
    width: 40px;
    height: 40px;
    border-radius: 6px;
    margin-right: 12px;
    flex-shrink: 0;
    flex-grow: 0;
  }

  strong {
    font-weight: 500;
  }

  .review-name {
    width: 100%;
    line-height: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .review-stars {
    margin-left: -2px;
  }

  .expanded-text {
    padding-left: 40px;
    color: $gray-text;
    padding-top: 20px;
    display: none;
  }

  &.expanded .btn-expand {
    background-color: $primary-light;
    color: $primary;

    &::after {
      content: "-";
    }
  }

  &.expanded .expanded-text {
    display: block;
  }

  &:last-child {
    border: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.modal-backdrop {
  z-index: 999999;
}

.show {
  display: block;
}

.new-modals,
.modal-dialog {
  padding-left: 30px !important;
  padding-right: 30px !important;

  h1 {
    font-size: 16px;
    font-weight: 600;
  }

  .modal-dialog {
    -moz-transform: none !important;
    -ms-transform: none !important;
    -o-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
  }

  .modal-header,
  .modal-footer,
  .modal-body {
    padding: 30px;

    h2 {
      font-size: inherit;
      font-weight: bold;
      margin-bottom: 1.75rem;
    }
  }

  .header-options {
    display: flex;
    justify-content: end;
    align-items: center;

    svg {
      width: 16px;
      height: 16px;
      margin-left: 12px;
    }
  }

  .modal-body {
    z-index: 3;
    position: relative;

    .close {
      position: absolute;
      right: 30px;
      top: 30px;

      img {
        width: 12px;
      }
    }
  }

  .modal-header {
    border-bottom: 1px solid $gray-border;
    position: relative;

    h1,
    h2,
    &>div {
      flex-grow: 1;
      font-size: 16px;
      font-weight: 600;
      margin: 0;
    }

    p {
      font-size: 14px;
      margin: 10px 0 0;
      font-weight: 400;
    }

    .back {
      display: none;
      margin-right: 30px;
    }
  }

  .modal-footer {
    line-height: 14px;
    padding-top: 16px;
    padding-bottom: 16px;
    z-index: 2;
    position: relative;

    .price {
    }

    &>p {
      font-size: 12px;
      line-height: 18px;
      margin-top: 13px;
      margin-bottom: 0;
    }

    &.gray-bg {
      background-color: $gray-bg;
      border-top: none;
    }

    .btn-quiet {
      border: none !important;
    }
  }

  .btn {
  }

  .btn-lg {
    font-size: 16px !important;
    height: 48px;
    font-weight: 500;
  }

  a.btn-lg {
    line-height: 48px;
  }

  @media (min-width: 650px) {
    & .modal-dialog.modal-md {
      max-width: 612px;
    }

    & .modal-dialog.modal-lg {
      max-width: 742px;
    }
  }

  @media (max-width: 650px) {
    &.modal-fullscreen-sm-down {
      background: #fff;

      .modal-header {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        background-color: #fff;
        z-index: 999999;
        border: none;
        padding-top: 20px;
        padding-bottom: 20px;
        border-radius: 0;

        .close {
          display: none;
        }

        .back {
          display: block;
        }
      }

      .modal-footer {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        z-index: 999999;
        box-shadow: 0 0 20px rgba(0, 0, 0, .09);
        border: none;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 0;
      }

      .modal-content {
        border-radius: 0;
        box-shadow: none;
      }

      .modal-dialog {
        padding: 0 !important;
        margin: 0 !important;
        max-width: none;
      }

      .modal-body {
        padding-top: 65px;
        padding-bottom: 95px;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }
}

.upload-contacts-modal {
  .select-show-row,
  .tags-row {
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
    min-width: 0;

    .form-label {
      margin-right: 20px;
      margin-bottom: 0;
      width: 100px;
      flex-shrink: 0;

      small {
      }
    }

    .dropdown {
      flex: 3;
      width: auto;
      min-width: 0;
    }
  }
  
  .select-show-row {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .tags-row {
  }

  .dropzone {
    max-width: 600px;
    margin: auto;

    &>div {
      padding: 25px;
    }
  }

  .selected-file {
    label {
      display: inline-block;
      max-width: 280px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0;
      vertical-align: middle;
    }
  }
}

////Pricing table & payment modals

.pricing-modal {
  .modal-body {
    background: $gray-bg;
    overflow: hidden;
    font-size: 13px;

    p {
      color: $gray-text;
    }

    p.text-dark {
      color: $dark-text;
    }

    h1,
    .h1 {
      font-size: 40px;
    }
  }

  .close {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .card {
    border: 1px solid $gray-border;

    .btn-quiet {
      color: dark-text;
    }

    .plan-name {
      background-color: $primary-light;
      border-radius: 100px;
      color: $primary;
      text-align: center;
      min-width: 78px;
      padding: 6px 20px;
      font-size: 13px;
      font-weight: 500;
    }

    .plan-description {
      min-height: 63px;
    }

    .plan-price {
      font-size: 40px;
    }

    &.featured {
      box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
      border-color: $red;

      .plan-name {
        color: #fff;
        background: rgb(255, 61, 0);
        background: linear-gradient(90deg, rgba(255, 61, 0, 1) 0%, rgba(245, 42, 42, 1) 62%, rgba(244, 48, 119, 1) 100%);
      }
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      margin: 0 0 20px;
      padding: 0 0 0 33px;
      position: relative;

      img {
        position: absolute;
        left: 3px;
        top: 2px;
      }
    }
  }

  .faq-container {
    background: #fff;
    margin: 4rem -5rem -5rem;
    padding: 5rem;

    &>div {
      max-width: 660px;
      margin: auto;
    }
  }
}

.toggle-buttons {
  width: 236px;
  border: 1px solid $gray-border;
  background: #fff;
  padding: 6px;
  border-radius: 6px;

  .btn {
    background: transparent !important;
    flex: 0 0 50%;
    padding: 0 !important;

    &.active {
      color: #fff !important;
      background-color: $primary !important;
    }
  }

  &-gray .btn.active {
    color: $dark-text !important;
    background-color: $gray-300 !important;
  }

  &-sm {
    padding: 3px;

    .btn {
      padding: 0 !important;
      height: 30px;
      line-height: 29px;
    }
  }
}

.user-success-modal {
  font-size: 16px;
}

.needs-upgrade-modal {
  .modal-body {
    color: $new-dark;

    h2 {
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 30px;
    }

    p {
      font-size: 14px;
      margin-bottom: 0;
    }

    .image {
      max-width: 75%;
      display: block;
      margin: -20px auto;
    }
  }

  .modal-footer {
    padding: 32px;
    display: block;
    text-align: center;
  }
}

.needs-upgrade-for-sms-modal,
.needs-activation-modal,
.subscribe-modal {
  color: $gray-text;
  text-align: center;

  img,
  svg {
    width: 100%;
    max-width: 367px;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      padding: 0 0 0 38px;
      margin: 0 0 18px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: -2px;
        width: 19px;
        height: 19px;
        border-radius: 100%;
        background: $cyan;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media (min-width: 576px) {
  .needs-upgrade-modal {
  }
}

.needs-upgrade-for-sms-modal {
  font-size: 14px;
  color: $new-dark;

  h2 {
    font-size: 24px !important;
    font-weight: 600 !important;
  }

  p {
    margin-bottom: 0;
  }

  ul {
    color: $toolbar-icon;
  }

  .fs-2 {
    font-size: 28px !important;
  }

  .fs-5 {
    font-size: 18px !important;
  }

  .fs-1 {
    font-size: 14px !important;
  }

  small {
    font-size: 11px !important;
  }

  .modal-lg {
    max-width: 996px !important;
  }

  .features {
    max-width: 820px;
    margin: auto;
  }
   
  .modal-footer {
    .text-left {
      line-height: 33px;
    }

    .btn {
      font-size: 16px !important;
      font-weight: 600;
    }
  }
}

.subscribe-modal {
  img {
    max-width: none;
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
    margin-top: -50px;
    margin-bottom: -100px;
  }
}

#push-backdrop {
  display: none;
  position: fixed;
  z-index: 999999;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #0003;

  &.show {
    display: block;
  }
}

.current-payment-method,
.edit-payment-method {
  max-width: 803px;
  margin-left: auto;
  margin-right: auto;
}

.current-payment-method {
  .svg-icon {
    width: 36px;
    height: auto;
    margin-right: 15px;
  }
}

.subscription-update-modal {
  .modal-lg {
    max-width: 996px !important;
  }

  .plans-list-item {
    border: 1px dashed $gray-border;
    transition: background-color .2s;
    cursor: pointer;

    .radio {
      display: block;
      width: 27px;
      height: 27px;
      background-color: $gray-bg;
      border: 8px solid $gray-bg;
      border-radius: 100%;
      margin-right: 25px;
      flex: 0 0 auto;
    }

    .price {
      white-space: nowrap;
      padding-left: 12px;
    }

    &,
    * {
      transition: color .2s, background-color .2s, border .2s;
    }

    &.selected {
      background-color: $primary;
      border-style: solid;

      .radio {
        border-color: $green;
      }

      .price strong {
        color: #fff !important;
      }

      .text-muted {
        color: $primary-text-muted !important;
      }
    }
  }

  .plan-features {
    height: 100%;

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        position: relative;
        margin: 0 0 9px;
        padding: 0 25px 0 0;

        img {
          position: absolute;
          right: 0;
          top: 2px;
        }
      }

      :last-child {
        margin-bottom: 0;
      }
    }
  }
}



.login-form {
  display: block !important;

  .form-group,
  .btn-google-login {
    margin-bottom: 1em;
  }

  &,
  .checkbox,
  .form-label {
    font-size: 14px !important;
  }

  .terms-checkbox {
    font-size: 13px !important;
  }

  .form-label {
    margin-bottom: 1rem !important;
  }

  .form-control,
  .btn {
    height: 48px !important;
  }

  .btn {
    font-size: 16px !important;
    padding-top: 0;
    padding-bottom: 0;
  }

  a.btn {
    line-height: 48px;
  }

  .form-or {
    margin: 2.4em 0;
    font-size: 12px;
    color: $gray-text;
    display: flex;
    white-space: nowrap;
    align-items: center;

    &::before,
    &::after {
      content: "";
      flex: 1;
      height: 1px;
      display: block;
      background: $gray;
    }

    &::before {
      margin-right: 15px;
    }

    &::after {
      margin-left: 15px;
    }
  }

  .btn-primary {
    color: #fff !important;
  }

  .fv-help-block {
    font-size: inherit;
    margin-top: .5em;
  }

  .small {
    font-size: 12px;
  }
}

.btn.btn-google-login {
  height: 48px;
  font-size: 14px !important;
  transition: background .2s;
  display: block;
  width: 100%;

  &:hover,
  &:focus {
  }

  &.loading {
    &::before {
      border-color: $primary !important;
      border-right-color: transparent !important;
    }

    svg {
      display: none;
    }
  }

  svg {
    height: 18px;
    width: 18px;
    vertical-align: sub;
    margin-top: -3px;
    margin-right: 8px;
  }
}

.login-aside {
  background-image: none !important;
  padding: 45px 15px !important;

  .logo {
    display: block;
    width: 117px;
    margin: auto;
  }

  .plan {
    margin: 60px auto 0;
    width: 350px;
    font-size: 12px;
    padding: 25px 20px;
    line-height: 25px;
    background-color: #0003;
    border-radius: 10px;
    color: $primary-light-text;

    h3 {
      font-size: inherit;
      font-weight: normal;
      line-height: inherit;
      color: inherit;
      margin: 0;
    }

    p {
      margin: 0;
    }

    .title {
      font-weight: 500;
      font-size: 14px;;
      color: white;
      display: flex;
      align-items: center;

      span {
        flex: 1;

        &:last-child {
          text-align: right;
          padding-left: 20px;
        }
      }
    }
  }

  .login-reviews-dots {
    display: none;
    margin-top: 34px;
    justify-content: center;

    a {
      display: block;
      flex: 0 0 10px;
      width: 10px;
      height: 10px;
      background-color: #0003;
      transition: background-color .2s;
      margin: 0 5px;
      border-radius: 100%;

      &.active {
        background-color: #fff9;
      }
    }
  }
}

.custom-fade-in {
  animation-duration: .3s;
  animation-name: custom-fade-in;
  animation-fill-mode: both;
}

.custom-fade-out {
  animation-duration: .3s;
  animation-name: custom-fade-out;
  animation-fill-mode: both;
  pointer-events: none;
}

@keyframes custom-fade-in {
  0% {
    opacity: 0;
    pointer-events: none;
  }

  100% {
    opacity: 1;
    pointer-events: all;
  }
}

@keyframes custom-fade-out {
  0% {
    opacity: 1;
    pointer-events: all;
  }

  100% {
    opacity: 0;
    pointer-events: none;
  }
}

.podcast-created-modal {
  .modal-body {
    &>img {
      width: 50px;
      display: block;
      margin: 0 auto 45px;
    }

    &>h1 {
      font-weight: bolder;
      font-size: 14px;
      margin-bottom: 13px;
    }

    &>p {
      font-size: 13px;
      margin-bottom: 40px;
    }
  }
}

.youtube-import-modal {
  .btn-google-login {
    max-width: 270px;
    margin-left: auto;
    margin-right: auto;
  }

  .channels-list {
    &.loading {
      * {
        opacity: .6;
      }
    }

    &.spinner::before {
      left: 50%;
      margin-left: -.75rem;
      z-index: 999;
    }

    .youtube-channel {
      color: inherit !important;
      transition: opacity .2s;

      .channel-picture {
        display: block;
        margin: 0 auto 9px;
        border-radius: 100%;
        width: 105px;
      }
    }
  }
}

.search-field {
  background-image: URL("data:image/svg+xml,%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Stockholm-icons-/-General-/-Search' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Crect id='bound' x='0' y='0' width='24' height='24'%3E%3C/rect%3E%3Cpath d='M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z' id='Path-2' fill='%23000000' fill-rule='nonzero' opacity='0.3'%3E%3C/path%3E%3Cpath d='M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z' id='Path' fill='%23999' fill-rule='nonzero'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: 8px center;
  padding-left: 41px;
}

.search-field-container {
  position: relative;
  width: 100%;

  .form-control {
    z-index: 1;
  }

  .results {
    position: absolute;
    left: 0;
    top: 51px;
    right: 0;
    background: white;
    border-radius: 6px;
    border: 1px solid $field-bg;
    box-shadow: 0 5px 10px #aaa2;
    z-index: 100;
    overflow: hidden;

    a {
      display: block;
      color: inherit !important;
      padding: 11px 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: background-color .2s;

      &:hover {
        background-color: $field-bg;
      }

      strong,
      span {
        display: inline-block;
        font-weight: normal;
        padding-right: 30px;

        &:last-child {
          padding-right: 0;
        }
      }
    }

    .text-muted {
      display: block;
      padding: 11px 14px;
    }
  }

  &.loading {
    &::before {
      left: auto;
      right: 14px;
      z-index: 2;
    }
  }
}

.rss-import-modal {
  .search-field {
    width: 100;
    max-width: 610px;
    margin: 35px auto;
  }

  .modal-footer {
    margin-top: -40px;
  }

  .search-results {
    overflow-y: auto;
    overflow-x: hidden;
    height: 228px;
    margin: 30px -30px -65px;
    border-top: 1px solid $gray-border;
    position: relative;

    &.loading {}

    .empty-search {
      margin-top: 69px;
      color: $gray-text;
    }

    .search-results-podcast {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      padding: 0 30px;
      text-align: left;

      &:first-child {
        margin-top: 15px;
      }

      img {
        flex: 0;
        border-radius: 6px;
        margin-right: 15px;
      }

      .btn {
        flex: 0 0 auto;
      }

      .podcast-name {
        flex: 1;
        margin-right: 15px;
        min-width: 0;

        strong {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 1.1rem;
        }
      }
    }
  }

  .options {
    max-width: 610px;
    margin: 0 auto;
    border-top: 1px solid $gray-border;
    padding-top: 32px;

    .btn {
      margin: 0 15px;
      border: none !important;
    }
  }

}

.rss-import-modal,
.share-code-import-modal {
  .product-card {
    display: inline-flex;
    max-width: 75%;
    margin: 32px auto;
    text-align: left;
    align-items: center;

    .product-image {
      flex: 0 0 auto;
      width: 72px;
      height: 72px;
      overflow: hidden;
      border-radius: 6px;
      margin-right: 15px;

      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
      }
    }

    .product-details {
      font-size: 12px;
      color: $gray-text;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;

      strong {
        font-size: 14px;
        color: $dark;
      }
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
  pointer-events: none;
}

.embed-btn {
  height: 100%;
}

.embed-dropdown {
  width: 287px !important;
  z-index: 1 !important;
  transform: translate(-185px, 46px) !important;

  .MuiSwitch-root {
    margin-left: -12px;
  }

  textarea {
    height: 105px !important;
  }
}

.embedded-player {
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 30px;
  background-color: #fff;
  align-items: stretch;

  &.loading,
  &.error {
    justify-content: center;
    align-items: center;
  }

  &.error {
    color: $gray-text;

    svg {
      height: 2em;
      width: 2em;
      display: block;
      margin: 0 auto 1em;
    }
  }

  .cover-image-container.lg {
    margin-right: 30px;
    width: 43%;

    &>div {
      padding-top: 100%;
      position: relative;
    }

    .cover-image {
      height: auto;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }

  .cover-image.sm {
    display: none;
  }

  .hiro-icon {
    .svg-icon {
      position: absolute;
      right: 30px;
      top: 30px;
      width: 30px;
      transition: filter .2s;
    }

    &:hover .svg-icon {
      filter: contrast(1.1);
    }
  }

  .player-body {
    flex: 1;
    display: flex;
    flex-direction: column;

    &>* {
      flex-shrink: 0;
    }

    .product {
      margin: 0 !important;
    }

    .product-name {
      margin-bottom: 4px;
      color: $gray-text;
      padding-right: 50px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    h1 {
      font-size: 18px;
      font-weight: 600;
      margin: 0;
      max-height: 39px;
      overflow: hidden;
      padding-right: 50px;
      word-break: break-word;
    }

    .player-controls {
      display: flex;
      margin: 17px 0;
      align-items: center;

      .play-pause {
        border: none !important;
        background-color: $dark !important;
        transition: background-color .2s;
        margin: 0;
        padding: 0;
        border-radius: 100%;
        width: 60px;
        height: 60px;
        flex: 0 0 auto;
        margin-right: 10px;

        &:hover,
        &:focus,
        &:active {
          background-color: $gray-400 !important;
        }

        .svg-icon {
          width: 30px;
          height: 30px;

          &.play {
            margin-left: 5px;
          }
        }
      }

      &>div {
        flex: 1;
      }

      .playback-rate {
        position: absolute;
        border: 1px solid $gray-600;
        background: #fff !important;
        font-size: 11px;
        border-radius: 2px;
        margin-top: 13px;
      }

      .waveform {
        position: relative;
        cursor: pointer;

        &>img {
          position: relative;
          width: 100%;
          z-index: 1;
        }

        .waveform-progress {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          background: $primary;
          mix-blend-mode: overlay;
          z-index: 2;
        }

        .time {
          position: absolute;
          z-index: 3;
          font-size: 12px;
          background: #fffc;
          white-space: nowrap;
          top: 50%;
          margin-top: -11px;
          margin-bottom: 0;
          right: 15px;
          padding: 2px 12px;
          pointer-events: none;
        }
      }
    }

    .list {
      flex: 1;
      border: 1px solid $gray;
      margin-top: 40px;
      position: relative;

      .scrollbar-view,
      .scrollbar-view>div {
        max-height: none !important;
        position: absolute !important;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        .scrollbar-track {
          left: auto !important;
          background: transparent;
        }
      }

      .list-item {
        border-bottom: 1px solid $gray;
        color: inherit !important;
        display: flex;
        min-width: 0;
        align-items: center;
        padding-right: 8px;

        &:last-child {
          border-bottom: none;
        }

        &>* {
          padding: 13px;
        }

        span {
          color: $gray-text;
          font-size: .93em;
        }

        .number {
          flex: 0 0 10%;
        }

        .duration {
          flex: 0 0 15%;
          text-align: right;
        }

        .title {
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &.single-episode {
    align-items: center;

    .player-body {
      .list {
        display: none;
      }
    }
  }

  @media (max-width: 600px) {
    .player-body {
      width: 100%;

      .list {
        margin-top: 20px;
      }
    }

    .product {
      display: flex;
      align-items: center;

      .cover-image {
        width: 60px;
        height: 60px;
        margin-right: 18px;
      }
    }

    .lg {
      display: none !important;
    }

    .sm {
      display: block !important;
    }
  }

  @media (max-height: 270px) {
    align-items: center;

    .player-body {
      .list {
        display: none;
      }
    }
  }

  @media (min-width: 601px) and (min-aspect-ratio: 111/50) {
    .cover-image-container.lg {
      width: calc(100vh - 60px);
    }
  }
}

.admin-page {
  font-size: 13px;

  &.login-container {
    height: 100vh;

    form {
      background-color: #fff;
      width: 100%;
      max-width: 270px;
    }
  }

  &.layout {
    padding: 0 12.5px;

    &>.row {
      min-height: 100vh;

      .sidebar {
        background-color: #fff;
        padding: 34px 40px;
        border-right: 1px solid $gray;

        a {
          display: block;
          color: inherit;
          padding: 6px 0;

          &:hover,
          &:focus {
            color: $primary;
          }

          &.active {
            font-weight: 600;
          }
        }

        .nav-logout {
          position: relative;
          top: 25px;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: -14px;
            height: 1px;
            background: rgba($gray, .5);
          }
        }
      }

      .main {
        padding: 40px;
      }
    }
  }

  h1 {
    margin-bottom: 2.25rem;
    font-size: 1.5rem;
    font-weight: 500;
  }

  th {
    white-space: nowrap;
    font-weight: 500 !important;
  }

  td {
    vertical-align: middle;
  }

  .btn-primary {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .d-block {
    width: 100%;
  }

  .card {
    font-size: inherit;
  }

  form {
    label {
      display: block;
      font-weight: 500;

      small {
        color: $gray-text;
        font-weight: normal;
      }
    }

    .notification-icons {
      .form-check {
        padding-left: 0;
        padding-bottom: 26px;
      }

      .form-check-input {
        margin-left: 0;
        margin-top: 5px;
      }
    }

    [type="checkbox"].form-check-input {
      margin-top: -19px;
      cursor: pointer;
    }

    .form-switch [type="checkbox"].form-check-input {
      margin-top: 0 !important;
    }
  }

  .alert {
    border: none;
    color: inherit;
    font-size: inherit;
  }
}

.admin-confirm-modal {
  .modal-footer {
    border: none;
    padding-top: 0;
  }
}

.notification-icon {
  width: 17px;
  height: 17px;
  display: flex !important;

  .svg-icon {
    width: 17px;
    height: 17px;
    margin: 0;
  }
}

.btn.notifications-item {
  height: 38px;
  padding: 0px 6px;
  border: none !important;
  background: transparent !important;
  position: relative;
  overflow: visible;

  &.has-notifications::before {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    top: -6px;
    left: 50%;
    margin-left: -4px;
    border-radius: 100%;
    background-color: $green;
    animation: blink 1s infinite;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

#notifications-popup {
  position: fixed;
  display: none;
  width: 405px;
  height: calc(100vh - 80px);
  max-height: 575px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.13);
  z-index: 99999;
  background-color: #fff;
  top: 60px;
  right: 79px;
  margin: 0 !important;

  @media (max-width: 768px) {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 75vw;
    height: 85vh;
    max-height: none;
  }

  &.show {
    display: flex;
    flex-direction: column;
  }

  .popup-header {
    background-color: $primary-dark;
    color: #fff;
    position: relative;
    padding: 20px;
    flex: 0 0 90px;

    h1 {
      font-size: 14px;
      font-weight: 500;
      margin: 6px 0 18px;
      color: white;
    }

    .btn-close {
      position: absolute;
      right: 20px;
      top: 20px;

      svg {
        width: 12px;

        [stroke] {
          stroke: #fff;
        }
      }
    }

    .tabs {
      display: flex;
      align-items: start;
      margin-bottom: -9px;

      a {
        margin-right: 20px;
        color: #ffffffa6 !important;
        position: relative;
        font-size: 12px;

        &:hover,
        &.active {
          color: #fff !important;
        }

        &.active {
          &::after {
            content: "";
            position: absolute;
            bottom: -16px;
            left: 0;
            right: 0;
            height: 2px;
            background-color: $primary-dark;
          }
        }
      }
    }
  }

  .popup-body {
    position: relative;
    flex: 1;
    opacity: 1;
    transition: opacity .2s;
    min-height: 0;

    .tabs {
      display: flex;
      width: 300%;
      transition: transform .3s;
      height: 100%;

      .tab {
        width: 33.3334%;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 30px 20px;
      }
    }

    &.tab-0 .tabs {
      transform: translateX(0);
    }

    &.tab-1 .tabs {
      transform: translateX(-33.3334%);
    }

    &.tab-2 .tabs {
      transform: translateX(-66.6668%);
    }

    .empty {
      text-align: center;
      font-size: 12px;
      color: $gray-300;
      margin: 0;
      height: 356px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .form-tab {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
      min-height: 100%;

      * {
        flex: 0 0 auto;
      }

      .image {
        max-width: 100%;
        margin-bottom: 15px;
      }

      h1 {
        font-size: 16px;
        font-weight: 600;
      }

      h2 {
        font-size: 12px;
        font-weight: 600;
        color: $gray-600;
      }

      p {
        font-size: 12px;
        line-height: 21px;
        margin: 0;
      }

      .btn {
        margin-top: 26px;
        min-width: 192px;
        max-width: 100%;
      }

      .form-card {}

      .thank-you-card {
        display: none;
        padding-top: 30px;
        font-weight: 600;
      }
    }

    .notification-item {
      align-items: center;
      margin-bottom: 20px;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }

      .notification-icon,
      .notification-image {
        flex: 0 0 auto;
        margin-right: 15px;
      }

      .notification-image {
        width: 30px;
        height: 30px;
        border-radius: 6px;
      }

      .notification-text {
        flex: 1;
        margin-right: 15px;
        line-height: 17px;
      }

      .notification-date {
        flex: 0 0 auto;
        white-space: nowrap;
      }
    }
  }

  &.loading {
    &::before {
      left: 50%;
      margin-left: -0.75rem;
      margin-top: 40px;
    }

    .popup-body {
      opacity: 0;
    }
  }
}

.image-cropper-modal {
  .cropper-container {
    position: relative;
    height: 50vh;
    border-radius: 4px;
    overflow: hidden;

    label {
      position: absolute;
      z-index: 9999;
      pointer-events: none;
      background-color: #fffb;
      color: #333;
      padding: 6px 13px;
      white-space: nowrap;
      border-radius: 3px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: opacity 1s;

      &.hidden {
        opacity: 0;
      }
    }
  }

  .zoom-container {
    font-weight: 600;
    color: #666;

    .custom-range {
      width: 180px;
      margin: 0 20px;
    }
  }
}

.dropdown-user-profile {
  .user-name {
    max-width: 170px;
  }

  .user-email {
    max-width: 272px;
  }

  .navi {
    max-height: 70vh;
    overflow-y: auto;
  }

  .dropdown-menu {

    .profile-placeholder,
    .profile-picture {
      width: 44px;
      height: 44px;
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: 17px;
    }
  }
}

.status-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-left: 20px;
  margin-bottom: 2px;
  vertical-align: middle;

  &.left {
    margin-left: 0;
    margin-right: 12px;
  }

  &.status-published {
    background-color: $green;
  }

  &.status-scheduled {
    background-color: $yellow;
  }

  &.status-unpublished {
    background-color: $red;
  }
}

.sms-code-form-modal {
  .product-form-item {
    margin-top: 20px;
    margin-bottom: 10px;
    align-items: center;

    .checkbox {
      flex-shrink: 0;
      margin-right: 15px;
    }

    .cover-image {
      width: 40px;
      height: 40px;
      flex-shrink: 0;
      margin-right: 15px;
    }

    label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.products-logos {
  .cover-image {
    width: 26px;
    height: 26px;
    border-radius: 100%;
    margin-right: -8px;
    display: inline-block
  }
}

.manage-sms-codes-page {
  .empty-icon {
    width: 301px;
    height: auto;
  }
}

.manage-campaigns-page {
  .empty-icon {
    width: 301px;
    height: auto;
  }
}

.manage-automations-page {
  .empty-icon {
    width: 440px;
    height: auto;
    margin-left: 39px;
  }
}

.empty-lottie {
  position: relative;
  z-index: 1;
}

.empty-content {
  position: relative;
  z-index: 2;
}

.color-picker {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: .25rem;

  @media (max-width: 768px) {
    justify-content: flex-start;
  }

  &.mw-540 {
    max-width: 540px;
  }

  &.disabled {
    opacity: .5;
    pointer-events: none;
  }

  button {
    border: none;
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    margin: 2px;

    path {
      fill: $dark;
    }

    &.dark path {
      fill: white;
    }

    &.color-auto {
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 600;
      white-space: nowrap;
      width: auto;
      padding-left: 25px;
      color: $gray-600;
      background: transparent;
      border: 1px dashed $gray;
      position: relative;

      &::before {
        content: "";
        width: 18px;
        height: 18px;
        position: absolute;
        left: 2px;
        top: 2px;
        border-radius: 2px;
        background-color: $gray-bg;
        z-index: 1;
      }

      svg {
        position: absolute;
        left: 5px;
        top: 5px;
        z-index: 2;
      }
    }
  }
}

.app-page {
  text-align: center;
  padding: 60px 25px;
  font-weight: normal;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 470px;
  margin: auto;
  width: 100%;

  &.desktop {
    font-size: 16px;
  }

  .logo {
    width: 114px;
    margin-bottom: 75px;
  }

  h3 {
    font-weight: 600;
    font-size: 1.12em;
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 0;
  }

  .error {
    padding: 200px 0;
    font-weight: 600;
  }

  .btn-primary {
    width: auto;

    &:disabled {
      background-color: $gray-300;
    }
  }

  label {
    text-align: left;
    display: block;
  }

  .form-control {
    width: 100%;
    background: #fff !important;
  }

  .password-wrapper {
    position: relative;

    .form-control {
      position: relative;
      z-index: 1;
      background-image: none !important;
    }

    .eye {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
      border: none !important;
      background: transparent !important;
    }
  }

  .validation-error {
    text-align: left;
    color: $red;
    font-weight: normal;
    font-size: .9em;
    margin-top: 7px;
  }

  .btn {
    font-size: bold;
  }
}

.listeners {

  &.loading,
  &.empty-state {
    height: 100%;

    .spinner {
      width: 100%;
      height: 100%;
    }

    .no-listeners,
    .no-episodes {
      padding-top: 130px;
      text-align: center;

      .container-small,
      .row {
        height: 100%;
        align-items: center;
      }
    }
  }

  .gray-badge {
    font-size: 11px;
    font-weight: 500;
  }

  .table-responsive {
    overflow-y: visible !important;

    .table {
      width: 100%;
      font-size: 13px;

      th {
        white-space: nowrap;

        .sort-icon {
          width: 1.4rem;
          margin-right: 5px;
          fill: $gray-400;
        }
      }

      .dropdown-toggle::after {
        display: none;
      }

      .device-android,
      .device-ios {
        display: inline-block;
        padding-left: 22px;
        background-position: center left;
        background-repeat: no-repeat;
      }

      .device-android {
        background-image: URL("data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gIoSUNDX1BST0ZJTEUAAQEAAAIYAAAAAAQwAABtbnRyUkdCIFhZWiAAAAAAAAAAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAAHRyWFlaAAABZAAAABRnWFlaAAABeAAAABRiWFlaAAABjAAAABRyVFJDAAABoAAAAChnVFJDAAABoAAAAChiVFJDAAABoAAAACh3dHB0AAAByAAAABRjcHJ0AAAB3AAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAFgAAAAcAHMAUgBHAEIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFhZWiAAAAAAAABvogAAOPUAAAOQWFlaIAAAAAAAAGKZAAC3hQAAGNpYWVogAAAAAAAAJKAAAA+EAAC2z3BhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABYWVogAAAAAAAA9tYAAQAAAADTLW1sdWMAAAAAAAAAAQAAAAxlblVTAAAAIAAAABwARwBvAG8AZwBsAGUAIABJAG4AYwAuACAAMgAwADEANv/bAEMACAYGBwYFCAcHBwkJCAoMFA0MCwsMGRITDxQdGh8eHRocHCAkLicgIiwjHBwoNyksMDE0NDQfJzk9ODI8LjM0Mv/bAEMBCQkJDAsMGA0NGDIhHCEyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMv/AABEIAA8ADQMBIgACEQEDEQH/xAAWAAEBAQAAAAAAAAAAAAAAAAAEAwf/xAAgEAACAgICAgMAAAAAAAAAAAABAgMRAAQFQRIhE1Gx/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/ANl291pZAY3dFr1Xo/uJ1+RRYVWXyLDsDrI7eiqSL8bEKUY0eqF4jX4+IwIz2xIv6wP/2Q==");
      }

      .device-ios {
        background-image: URL("data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gIoSUNDX1BST0ZJTEUAAQEAAAIYAAAAAAQwAABtbnRyUkdCIFhZWiAAAAAAAAAAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAAHRyWFlaAAABZAAAABRnWFlaAAABeAAAABRiWFlaAAABjAAAABRyVFJDAAABoAAAAChnVFJDAAABoAAAAChiVFJDAAABoAAAACh3dHB0AAAByAAAABRjcHJ0AAAB3AAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAFgAAAAcAHMAUgBHAEIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFhZWiAAAAAAAABvogAAOPUAAAOQWFlaIAAAAAAAAGKZAAC3hQAAGNpYWVogAAAAAAAAJKAAAA+EAAC2z3BhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABYWVogAAAAAAAA9tYAAQAAAADTLW1sdWMAAAAAAAAAAQAAAAxlblVTAAAAIAAAABwARwBvAG8AZwBsAGUAIABJAG4AYwAuACAAMgAwADEANv/bAEMACAYGBwYFCAcHBwkJCAoMFA0MCwsMGRITDxQdGh8eHRocHCAkLicgIiwjHBwoNyksMDE0NDQfJzk9ODI8LjM0Mv/bAEMBCQkJDAsMGA0NGDIhHCEyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMv/AABEIAA8ADAMBIgACEQEDEQH/xAAWAAEBAQAAAAAAAAAAAAAAAAAEBQf/xAAfEAACAQQCAwAAAAAAAAAAAAABAgMABBEhBRIxQVH/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEQMRAD8A2efkmk7xhAEOhg4NUrWRpbZHZVUn0PlBl4p2lZo3UKdgGm2lubeAIzdjnOvAoP/Z");
      }
    }
  }
}

.form-control.search {
  background-image: URL("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Stockholm-icons-/-General-/-Search' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Crect id='bound' x='0' y='0' width='24' height='24'%3E%3C/rect%3E%3Cpath d='M14.2928932 16.7071068 C13.9023689 16.3165825 13.9023689 15.6834175 14.2928932 15.2928932 C14.6834175 14.9023689 15.3165825 14.9023689 15.7071068 15.2928932 L19.7071068 19.2928932 C20.0976311 19.6834175 20.0976311 20.3165825 19.7071068 20.7071068 C19.3165825 21.0976311 18.6834175 21.0976311 18.2928932 20.7071068 L14.2928932 16.7071068 Z' id='Path-2' fill='%23000000' fill-rule='nonzero' opacity='0.3'%3E%3C/path%3E%3Cpath d='M11 16 C13.7614237 16 16 13.7614237 16 11 C16 8.23857625 13.7614237 6 11 6 C8.23857625 6 6 8.23857625 6 11 C6 13.7614237 8.23857625 16 11 16 Z M11 18 C7.13400675 18 4 14.8659932 4 11 C4 7.13400675 7.13400675 4 11 4 C14.8659932 4 18 7.13400675 18 11 C18 14.8659932 14.8659932 18 11 18 Z' id='Path' fill='%23000000' fill-rule='nonzero'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: center left 10px;
  padding-left: 40px;
}

.card-toolbar {
  width: 100%;
  
  .search {
    flex-basis: 25%;
  }

  .buttons {
    display: flex;
    flex-grow: 1;
    align-items: stretch;
    justify-content: end;

    .btn {
      margin-left: 18px;

      svg {
        width: 16px;
        margin: 0 5px;
        vertical-align: sub;
      }
    }

    .btn-delete {
      padding: 0 .5rem !important;
    }
  }
}

.listener-activity-modal {
  .table {
    width: 100%;
    font-size: 13px;
  }

  .gray-badge,
  .blue-badge,
  .green-badge {
    font-weight: 500;
  }

  .progress-container {
    display: inline-flex;
    align-items: center;

    .progress-bar {
      width: 171px;
      height: 12px;
      background-color: $gray-bg;
      border-radius: 100px;

      .fill {
        display: block;
        float: left;
        height: 100%;
        background-color: $primary;
        border-radius: 100px;
      }
    }

    label {
      margin-bottom: 0;
      margin-left: 15px;
      font-weight: 500;
    }
  }
}